import {useThemeContext} from 'providers/themeProvider';
import {contrast, shouldUseDarkColor} from 'utils/colorUtils';

export const useOverBrandPrimaryTextColor = () => {
  const {theme} = useThemeContext();
  return contrast(theme.primaryColor);
};

export const usePrimaryContrastColorIsDark = () => {
  const {theme} = useThemeContext();
  return shouldUseDarkColor(theme.primaryColor);
};

export default useOverBrandPrimaryTextColor;
