import {
  APContact,
  CrmIntegrationType,
  Currency,
  OrderStage,
  OrderType,
  Person,
  PaymentMethod,
  User,
  FinanceSystemType,
} from 'types';
import {
  getNumberOfMonths,
  format,
  getNumberOfDays,
  humanizeDateDiff,
} from './dateUtils';
import {isNumber} from 'utils/price';

const locale = (currency: Currency) => {
  const o = {
    [Currency.usd]: 'en-US',
    [Currency.eur]: 'fr-FR',
    [Currency.gbp]: 'en-GB',
    [Currency.aud]: 'en-US',
    [Currency.cad]: 'en-US',
  };
  return o[currency];
};

interface FormatCurrencyOptions {
  displayCurrencySymbol?: Boolean;
  fractionalCents?: Boolean;
  skipCentsToDollarsConversion?: Boolean;
}

const defaultFormatCurrencyOptions = {
  displayCurrencySymbol: true,
  fractionalCents: false,
  skipCentsToDollarsConversion: false,
};

export const formatCurrency = (
  amount: number | string,
  currency: Currency,
  options: FormatCurrencyOptions = defaultFormatCurrencyOptions,
) => {
  if (!currency) {
    console.warn(
      `tried to format a number without a valid currency, fell back to USD`,
    );
    currency = Currency.usd;
  }

  const isString = typeof amount === 'string';
  // amount can be string because of usage, so we convert it to a number here
  const numberAmount: number = isString ? parseFloat(amount) : amount;
  const dollars = options?.skipCentsToDollarsConversion
    ? numberAmount
    : numberAmount / 100;

  let minimumFractionDigits = 2;
  let maximumFractionDigits = 2;

  if (options?.fractionalCents) {
    maximumFractionDigits = 7;
  }

  const val = new Intl.NumberFormat(locale(currency), {
    style: options.displayCurrencySymbol === false ? 'decimal' : 'currency',
    currency,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(dollars);

  // check if we need an additional 0 (10.1 -> 10.10)
  if (/\.\d$/.test(val)) {
    return val + '0';
  }

  return val;
};

export const getCurrencyFormatter =
  (currency: Currency) => (amount: number | string) => {
    return formatCurrency(amount, currency);
  };

export const sentenceFromWordList = (words: string[]): string => {
  if (words.length === 1) {
    return words[0];
  }

  let sentence = '';

  for (let i = 0; i < words.length; i++) {
    const word = words[i];
    if (i === words.length - 1) {
      sentence += ', and ' + word;
    } else if (i === 0) {
      sentence += word;
    } else {
      sentence += ', ' + word;
    }
  }

  return sentence;
};
/**
 *
 * @param {string} sentence - any string
 * @returns lower case version of `sentence` after replacing underscores with spaces
 * @example
 * // returns 'foo bar'
 * snakeCaseToHuman('FOO_BAR');
 */
export const snakeCaseToHuman = (sentence: string) => {
  return sentence.replace(/_/g, ' ').toLowerCase();
};

export const camelCaseToHuman = (sentence: string) => {
  return sentence.replace(/([A-Z])/gu, ' $1');
};

export const shortName = (user: User | Person): string => {
  if (!user) {
    return null;
  }
  return `${user.firstName[0]} ${user.lastName}`;
};

export const fullName = (user: User | Person): string => {
  const name = `${user?.firstName} ${user?.lastName}`;
  return user?.firstName ? name : null;
};

export const fullNameAndEmail = (user: User | Person): string => {
  const name = fullName(user);
  const nameAndEmail = [];

  if (name) {
    nameAndEmail.push(name);
  }
  if (user?.email) {
    nameAndEmail.push(user.email);
  }
  return nameAndEmail.join(', ');
};
/**
 *
 * @param {string} text - any string
 * @param lowerCaseOthers
 * @returns `text` with first character capitalized
 * @see capitalizeAllWords to include space separated substrings of `text`
 */
export const capitalize = (text: string, lowerCaseOthers?: boolean): string => {
  return (
    text.charAt(0).toUpperCase() +
    (lowerCaseOthers ? text.slice(1).toLowerCase() : text.slice(1))
  );
};

export const capitalizeAllWords = (
  text: string,
  separator?: string,
  lowerCaseOthers?: boolean,
): string => {
  return text
    .split(separator || ' ')
    .map((word) => capitalize(word, lowerCaseOthers))
    .join(' ');
};

export const longDate = (date: Date): string => {
  return format(date, 'PPPP');
};

export const contractTerm = (start: Date, end: Date): string => {
  const formattedStart = format(start, 'MMM d, yyyy', true);
  const formattedEnd = format(end, 'MMM d, yyyy', true);
  const durationText = `${formattedStart} - ${formattedEnd}`;

  const numDays = getNumberOfDays(start, end);
  if (numDays < 0) {
    console.error('Error evaluating contract term duration');
    return '';
  }
  if (numDays < 30) {
    const daysText = humanizeDateDiff(end, start);
    return `${durationText} (${daysText})`;
  }
  const numMonths = getNumberOfMonths(start, end);
  return `${durationText} (${numMonths} mo.)`;
};

export const getSupportedCurrencyLabel = (currency: Currency): string => {
  switch (currency) {
    case Currency.eur:
      return 'EUR (€)';
    case Currency.gbp:
      return 'GBP (£)';
    case Currency.aud:
      return 'AUD (A$)';
    case Currency.cad:
      return 'CAD (CA$)';
    default:
      return 'USD ($)';
  }
};

export const getSupportedCurrencies = (
  supportedCurrenciesArray: Currency[],
): string => {
  if (!supportedCurrenciesArray.length) {
    return '';
  }
  const formattedArray = supportedCurrenciesArray.map((currency: Currency) => {
    return getSupportedCurrencyLabel(currency);
  });
  return formattedArray.join(', ');
};

export const humanReadableStageName = (orderStage: OrderStage): string => {
  if (orderStage === OrderStage.sellerSigning) {
    return 'awaiting countersignature';
  }
  return orderStage.replaceAll('_', ' ').toLowerCase();
};

/*
 * Make a human readable button label depending on orderType and
 * if its a create operation.
 *
 * @param {OrderType} orderType
 * @param {boolean} isCreate
 * @returns a string that can be used to describe a button action
 */
export const humanReadableCreateOrder = (
  orderType: OrderType,
  isCreate: boolean = true,
): string => {
  const prefix = isCreate ? 'Create' : 'Update';
  switch (orderType) {
    case OrderType.renewal:
      return `${prefix} renewal`;
    case OrderType.recast:
      return `${prefix} recast`;
    case OrderType.upgrade:
      return `${prefix} upgrade`;
    default:
      return prefix;
  }
};

export const humanReadableCrmType = (type: CrmIntegrationType): string => {
  if (type === CrmIntegrationType.SALESFORCE) {
    return 'Salesforce';
  } else if (type === CrmIntegrationType.HUBSPOT) {
    return 'HubSpot';
  }
};

export const humanReadableFinanceSystemType = (
  type: FinanceSystemType,
): string => {
  if (type === FinanceSystemType.QUICKBOOKS) {
    return 'Quickbooks';
  } else if (type === FinanceSystemType.XERO) {
    return 'Xero';
  }
};

export const formatAccountsPayableEmails = (
  accountsPayableContactsArray: APContact[],
): string => {
  return accountsPayableContactsArray.length === 0
    ? ''
    : accountsPayableContactsArray
        .map((apContact: APContact) => {
          return apContact.email;
        })
        .join(', ');
};

export const parseAccountsPayableEmails = (
  accountsPayableContacts: string,
): APContact[] => {
  return accountsPayableContacts.length === 0
    ? []
    : accountsPayableContacts
        // remove whitespace from the string first
        .replace(' ', '')
        .split(',')
        .map((email: string) => {
          return {email: email.trim()};
        });
};

export const humanReadablePaymentMethodType = (type: PaymentMethod): string => {
  switch (type) {
    case PaymentMethod.creditCard:
      return 'Credit card';
    case PaymentMethod.ach:
      return 'ACH';
    case PaymentMethod.check:
      return 'Check';
    case PaymentMethod.wire:
      return 'Wire';
    default:
      return '';
  }
};

export const formatSingularOrPlural = (quantity: number, unit: string) => {
  return [1, -1].includes(quantity) ? unit : `${unit}s`;
};

export const humanizeNumber = (number: number | string): number | string => {
  if (!isNumber(number)) {
    return number;
  }
  return Number(number).toLocaleString();
};
