import {
  BillingSchedule,
  BrickPricing,
  Currency,
  Discount,
  DiscountLineItem,
  Pricing,
  PricingLineItem,
  PricingTierSystemType,
  SKUScheduleType,
} from 'types';

export function getAnnualFee(
  brickPricing: BrickPricing,
  billingSchedule: BillingSchedule,
  currency: Currency = Currency.usd,
): number {
  if (brickPricing.tiers[currency].length === 0) {
    return 0;
  }
  const baseTier = brickPricing.tiers[currency][0];
  if (brickPricing.brick.skuSchedule === SKUScheduleType.usage) {
    return baseTier.priceUsage;
  }
  if (brickPricing.isOneTimePurchase) {
    return baseTier.priceOneTime;
  }
  return billingSchedule === BillingSchedule.monthly
    ? baseTier.priceMonthly * 12
    : baseTier.priceMonthly;
}

export function getFee(
  brickPricing: BrickPricing,
  billingSchedule: BillingSchedule,
  currency: Currency = Currency.usd,
): number {
  if (brickPricing.tiers[currency].length === 0) {
    return 0;
  }

  let idx = 0;
  if (brickPricing.includedUnits && brickPricing.tiers[currency].length > 1)
    idx = 1;

  const baseTier = brickPricing.tiers[currency][idx];

  if (brickPricing.brick.skuSchedule === SKUScheduleType.usage) {
    return baseTier.priceUsage;
  }
  if (brickPricing.isOneTimePurchase) {
    return baseTier.priceOneTime;
  }
  return billingSchedule === BillingSchedule.monthly
    ? baseTier.priceMonthly
    : baseTier.priceAnnual;
}

function getTieredPrice(
  brickPricing: BrickPricing,
  quantity: number,
  key: 'priceMonthly' | 'priceAnnual',
  currency: Currency = Currency.usd,
): number {
  const sortedTiers = brickPricing.tiers[currency];

  let unitCount = 0;
  let price = 0;

  for (let i = 0; i < sortedTiers.length; i++) {
    const outstanding = quantity - unitCount;

    const tier = sortedTiers[i];
    const pricingAtThisTier = tier[key];
    // if we have includedUnits, we want to show the price of the first tier that is not included
    // if its flat rate, we only have one tier, so dont continue
    if (
      brickPricing.includedUnits &&
      brickPricing.includedUnits >= tier.lowerBound &&
      brickPricing.pricingTierSystem !== PricingTierSystemType.flatRate
    ) {
      continue;
    }
    if (i === sortedTiers.length - 1) {
      // this is the last Tier so price everything remaining at this value
      price += pricingAtThisTier * outstanding;
      break;
    } else {
      const nextTier = sortedTiers[i + 1];
      const availableAtThisTier = nextTier.lowerBound - tier.lowerBound;
      // also if this tier has enough available units to meet all our
      // outstanding, we're done too
      if (availableAtThisTier >= outstanding) {
        price += pricingAtThisTier * outstanding;
        break;
      } else {
        unitCount += availableAtThisTier;
        price += pricingAtThisTier * availableAtThisTier;
      }
    }
  }

  return price;
}

export function calculateSKUPeriodPrices(
  brickPricing: BrickPricing,
  quantity: number,
  startingQuantity = 0,
  currency: Currency = Currency.usd,
): {monthly: number; annual: number; annualizedMonthlyFee: number} {
  const singleUnitMonthlyPrice = getFee(
    brickPricing,
    BillingSchedule.monthly,
    currency,
  );
  const singleUnitAnnualPrice = getFee(
    brickPricing,
    BillingSchedule.annually,
    currency,
  );

  const simpleNetQuantity = quantity - startingQuantity;

  let output = {
    monthly: singleUnitMonthlyPrice * simpleNetQuantity,
    annual: singleUnitAnnualPrice * simpleNetQuantity,
    annualizedMonthlyFee: singleUnitMonthlyPrice * 12 * simpleNetQuantity,
  };

  brickPricing.tiers[currency].sort((a, b) => a.lowerBound - b.lowerBound);

  let lastFitTier = undefined;

  if (brickPricing.pricingTierSystem === PricingTierSystemType.volumeDiscount) {
    for (const tier of brickPricing.tiers[currency]) {
      if (tier.lowerBound < simpleNetQuantity) {
        lastFitTier = tier;
      } else {
        break;
      }
    }
    // no lastFitTier would use basepricing so we're good to pass
    if (lastFitTier) {
      output.monthly = lastFitTier.priceMonthly * simpleNetQuantity;
      output.annual = lastFitTier.priceAnnual * simpleNetQuantity;
      output.annualizedMonthlyFee =
        lastFitTier.priceMonthly * simpleNetQuantity * 12;
    }

    return output;
  }

  const baseMonthly = getTieredPrice(
    brickPricing,
    startingQuantity,
    'priceMonthly',
    currency,
  );
  const baseAnnual = getTieredPrice(
    brickPricing,
    startingQuantity,
    'priceAnnual',
    currency,
  );
  output.monthly =
    getTieredPrice(brickPricing, quantity, 'priceMonthly', currency) -
    baseMonthly;
  output.annual =
    getTieredPrice(brickPricing, quantity, 'priceAnnual', currency) -
    baseAnnual;
  output.annualizedMonthlyFee = output.monthly * 12;

  return output;
}

export const centsToDollars = (
  i: number,
  decimalPlaces: number = 2,
): number => {
  if (i === 0) {
    return 0;
  }
  if (!i) {
    return null;
  }

  if (decimalPlaces === 2) {
    return parseFloat((i / 100).toFixed(2));
  }

  return Number((i / 100).toFixed(decimalPlaces));
};

export const dollarsToCents = (
  i: number,
  decimalPlaces: number = 2,
): number => {
  if (i === 0) {
    return 0;
  }
  if (!i) {
    return null;
  }

  if (decimalPlaces === 2) {
    return Math.round(i * 100);
  }

  return Number((i * 100).toFixed(decimalPlaces));
};

// percent: the raw percent amount (e.g. if 20%, provide 20, not 0.20)
// total: in cents
export const convertPercentToCurrency = (
  percent: number | null,
  total: number | null,
) => {
  if (percent === null || total === null) {
    return null;
  }
  return (percent / 100) * total;
};

// amount: the raw dollar amount (e.g. if $20, provide 20, not 2000)
// total: in cents
export const convertCurrencyToPercent = (
  amount: number | null,
  total: number | null,
) => {
  if (total === 0 || total === null || amount === null) {
    return null;
  }
  return (amount * 100) / total;
};

export const isNumber = (i: any): boolean => {
  if (i === null || i === undefined || i.length === 0) {
    return false;
  }
  return !isNaN(Number(i));
};

export const numberOrNull = (i: any): number | null => {
  if (i === null || i === undefined || i.length === 0) {
    return null;
  }
  const n = Number(i);
  if (isNaN(n)) {
    return null;
  }
  return n;
};

export const toFixedNumber4 = (i: number): number => {
  if (i === null) {
    return null;
  }

  return Math.round(i * 1e4) / 1e4;
};

export const toFixedNumber2 = (i: number): number => {
  if (i == null) {
    return null;
  }

  return Math.round(i * 1e2) / 1e2;
};

export const getUsageLineItem = (lineItems: PricingLineItem[]) => {
  return lineItems?.filter(
    (lineItem: PricingLineItem) =>
      lineItem.skuSchedule === SKUScheduleType.usage,
  );
};

export const getDiscountedLineItemBrickIds = (
  lineItems: DiscountLineItem[],
) => {
  return lineItems
    ?.filter((lineItem: DiscountLineItem) => isDiscountedLineItem(lineItem))
    .map((lineItem: DiscountLineItem) => lineItem.brickId);
};

export const hasVariableDiscounts = (pricing: Pricing, discount: Discount) => {
  const usageLineItem = getUsageLineItem(pricing?.lineItems);

  const discountedLineItemBrickIds = getDiscountedLineItemBrickIds(
    discount.lineItems,
  );

  const result = usageLineItem?.some((usageBrick: PricingLineItem) =>
    discountedLineItemBrickIds.includes(usageBrick.brickId),
  );

  return result;
};

export const hasDiscounts = (discount: Discount) => {
  const result = discount?.lineItems?.some(isDiscountedLineItem);

  return result;
};

export const isDiscountedLineItem = (lineItem: DiscountLineItem) => {
  const {oneTimeRate, oneTimeFixed, salesRate} = lineItem ?? {};
  return !!oneTimeRate || !!oneTimeFixed || !!salesRate;
};

export const getBaseDiscountAmount = (pricing: Pricing, discount: Discount) => {
  return pricing?.lineItems?.reduce((baseAmount, currentLineItem) => {
    if (currentLineItem.skuSchedule === SKUScheduleType.usage) {
      // don't include usage brick discounts
      return baseAmount;
    }

    const lineItemDiscount = discount?.lineItems?.find(
      (discountLineItem: DiscountLineItem) => {
        return discountLineItem.brickId === currentLineItem.brickId;
      },
    );

    if (!isDiscountedLineItem(lineItemDiscount)) {
      return baseAmount;
    }

    const {oneTimeRate, oneTimeFixed, salesRate} = lineItemDiscount ?? {};

    const totalLineItemDiscount =
      convertPercentToCurrency(salesRate, currentLineItem.subTotal) +
      convertPercentToCurrency(oneTimeRate, currentLineItem.subTotal) +
      oneTimeFixed;

    return baseAmount + totalLineItemDiscount;
  }, 0);
};
