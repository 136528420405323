import React, {forwardRef} from 'react';
import CSS from 'csstype';
import RightArrow from 'public/right-arrow.svg';
import styles from './button.module.scss';
import X from 'public/icons/x.svg';
import {
  useOverBrandPrimaryTextColor,
  usePrimaryContrastColorIsDark,
} from 'hooks/useOverBrandTextColor';
import useBrandBackgroundColor from 'hooks/useBrandBackgroundColor';
import {SimpleKV} from 'types';
import Tag from 'components/ui/tag';

export type ButtonType =
  | 'primary'
  | 'active'
  | 'secondary'
  | 'tertiary'
  | 'text'
  | 'brandedPrimary'
  | 'brandedPrimaryDark'
  | 'brandedSecondary';

type ButtonSize = 'small' | 'normal' | 'large' | 'superLarge';

export interface ButtonParentProps {
  'data-test'?: string;
  'data-test-id'?: string;
  onClick?: (e: React.MouseEvent) => void;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  outline?: boolean;
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  iconOnly?: boolean;
  iconRenderFun?: () => React.ReactNode;
  iconTrailing?: boolean;
  ignoreTheme?: boolean;
  style?: CSS.Properties;
  type?: 'button' | 'submit' | 'reset';
  onBlur?: (event: React.FocusEvent) => void;
  id?: string;
  dataAttributes?: SimpleKV;
  fullWidth?: boolean;
  noFill?: boolean;
}

export interface ButtonProps extends ButtonParentProps {
  /**
   * Title isn't rendered but does show up on mouseover.
   */
  title: string;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (props: ButtonProps, ref: React.ForwardedRef<HTMLButtonElement>) => {
    const {
      children,
      className,
      onClick,
      disabled,
      iconRenderFun,
      iconOnly,
      outline = false,
      buttonType = 'normal',
      buttonSize = 'normal',
      iconTrailing = false,
      ignoreTheme = false,
      style = {},
      type,
      onBlur,
      id,
      dataAttributes,
      fullWidth,
      noFill,
    } = props;

    const backgroundColor = useBrandBackgroundColor();
    const color = useOverBrandPrimaryTextColor();
    const darkIcon = usePrimaryContrastColorIsDark();

    const shouldIgnoreTheme =
      ignoreTheme ||
      outline ||
      ['text', 'secondary', 'tertiary'].includes(buttonType) ||
      /#ffffff/i.test(backgroundColor);

    const themeStyle = shouldIgnoreTheme
      ? {}
      : {
          backgroundColor,
          color,
          borderColor: backgroundColor,
        };

    const baseClass = [
      styles.baseButton,
      styles[buttonType],
      styles[buttonSize],
    ];
    if (iconOnly) {
      baseClass.push(styles.iconOnly);
    }
    if (iconTrailing) {
      baseClass.push(styles.iconTrailing);
    }
    if (className) {
      baseClass.push(className);
    }
    if (outline) {
      baseClass.push(styles.outline);
      if (noFill) {
        baseClass.push(styles.noFill);
      } else {
        baseClass.push(styles.darkIcon);
      }
    }
    if (!ignoreTheme && darkIcon) {
      baseClass.push(styles.darkIcon);
    }
    if (fullWidth) {
      baseClass.push(styles.fullWidth);
    }
    if (noFill) {
      baseClass.push(styles.noFill);
    }
    return (
      <button
        data-test={props['data-test']}
        data-test-id={props['data-test-id']}
        id={id}
        style={{...themeStyle, ...style}}
        ref={ref}
        onClick={onClick}
        className={baseClass.join(' ')}
        title={props.title}
        type={type}
        onBlur={onBlur}
        {...dataAttributes}
        data-checked={props['data-checked']}
        disabled={disabled}>
        {iconRenderFun ? iconRenderFun() : null}
        {children}
      </button>
    );
  },
);

export const BigButton = (props: ButtonProps) => {
  const {className, ...rest} = props;
  return <Button {...rest} className={[className, styles.big].join(' ')} />;
};

export const BigArrowButton = (props: ButtonProps) => {
  const {className, children, ...rest} = props;
  return (
    <Button
      {...rest}
      className={[className, styles.big, styles.arrowButton].join(' ')}>
      <p>{children}</p>
      <RightArrow />
    </Button>
  );
};

export const CloseButton = (props: ButtonProps) => {
  const {className} = props;
  return (
    <Button
      buttonType={'secondary'}
      iconOnly
      className={[styles.closeButton, className].join(' ')}
      iconRenderFun={() => <X />}
      {...props}
    />
  );
};

type FilterProps = ButtonProps & {tagLabel?: string; active?: boolean};

export const FilterButton = (props: FilterProps) => {
  const {active, className, children, tagLabel, ...rest} = props;

  const buttonClassNames = [className, styles.filter];

  if (active) {
    buttonClassNames.push(styles.filterActive);
  }
  return (
    <Button
      buttonType="tertiary"
      className={buttonClassNames.join(' ')}
      {...rest}>
      {children}
      {tagLabel && <Tag label={tagLabel} tagColor="inactive"></Tag>}
    </Button>
  );
};

export default Button;
