import {ApolloError} from '@apollo/client/errors';
import {FeatureCode} from 'components/featureGates';
import {AppProps} from 'next/app';
import {NextPage} from 'next/types';
import {ReactElement, ReactNode} from 'react';
import {ChosenBrick} from 'reducers/buildOrderReducer';

declare global {
  interface Window {
    Atlas: any;
    Sentry: any;
    Beamer: any;
    entri: any;
    ConverSight: any;
  }
}

// For easier switching to possibly BigInt in the future.
export type MoneyDecimal = number;
export type MoneyCents = number;
export type QuantityNumber = number;
// ex: 1.4 = 1.4%
export type PercentageDecimalNumber = number;

export interface RenewalSettings {
  notificationCadence: Record<number, boolean>;
}

export interface Product {
  id: string;
  slug: string;
  name: string;
  seller: Seller;
  description: string;
  descriptionShort: string;
  createdAt: Date;
  updatedAt: Date;
  logoImage: string;
  invoiceEmailAutoSend: boolean;
  active: boolean;
  featureSet: Feature[];
  selectedSkuOrder: string[];
  faqSet: FAQ[];
  primaryColor?: string;
  acceptsCreditCardPayment: boolean;
  acceptsAchPayment: boolean;
  acceptsCheck: boolean;
  numSkus?: QuantityNumber;
  minimumOrderFormTcv?: MoneyCents;
  autoRenewalDefault: boolean;
  renewalSettings: RenewalSettings;
  plans: Plan[];
  redirectUrl: string;
  paymentDueUponReceipt?: number;
  isStripeIntegrationEnabled?: boolean;
}

export interface TaxRemitReport {
  state: string;
  totalAmountBilled: MoneyCents;
  totalTax: MoneyCents;
}
export interface StatewiseTaxDetailReport {
  invoiceId?: string;
  company?: string;
  invoiceOrderId?: string;
  region?: string;
  totalAmountBilled: MoneyCents;
  totalTax: MoneyCents;
}

export enum UsageFrequencyType {
  'second' = 'SECOND',
  'minute' = 'MINUTE',
  'hour' = 'HOUR',
  'day' = 'DAY',
}

export enum UsagePriceCalculationType {
  'counter' = 'COUNTER',
  'gauge' = 'GAUGE',
}

export enum SKUScheduleType {
  'subscription' = 'SUBSCRIPTION',
  'oneTime' = 'ONE_TIME',
  'usage' = 'USAGE',
  'milestone' = 'MILESTONE',
}

export const SKU_SCHEDULE_LABEL = {
  [SKUScheduleType.usage]: 'Usage',
  [SKUScheduleType.oneTime]: 'One time',
  [SKUScheduleType.subscription]: 'Subscription',
  [SKUScheduleType.milestone]: 'Milestone',
};

// This type is for fronetend only.
export enum SKUCategory {
  'plans' = 'plans',
  'subscriptions' = 'subscriptions',
  'oneTime' = 'oneTime',
  'milestone' = 'milestone',
  'preCommitted' = 'preCommitted',
  'payAsYouGo' = 'payAsYouGo',
}

export interface FAQ {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  question: string;
  answer: string;
  active: boolean;
}

export interface SKUFeature {
  id: string;
  plan: Plan;
  feature: Feature;
  note: string;
  showCheckMark?: boolean;
  alive: boolean;
}

export interface Feature {
  id: string;
  name: string;
  summary: string;
  alive: boolean;
  active: boolean;
  skufeatureSet?: SKUFeature[];
}

export interface User {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  orderSet: Order[];
  employment?: Employment;
  createdAt: Date;
  alive: boolean;
  hasCrmConnection: boolean;
}

export interface Person {
  id: string;
  firstName: string;
  lastName: string;
  preferredName?: string;
  email: string;
  createdAt: Date;
  company?: Company;
  role?: EmploymentRole;
}

export interface Employment {
  id: string;
  company: Company;
  role: EmploymentRole;
  sellerOrderFormSigner: boolean;
  notificationPreferences: NotificationPreferences;
  isCompanyAdmin: boolean;
  discountingPower: PercentageDecimalNumber;
  user: User;
  showWorkspaceWarning: boolean;
}

export interface NotificationPreferences {
  id: string;
  orderFormSigned: boolean;
  orderFormSent: boolean;
  email: boolean;
}

export interface Company {
  id: string;
  name: string;
  sellerAccount?: Seller;
  employmentSet: Employment[];
  createdAt: Date;
  address: Address;
  stripeDefaultCardId: string;
  autoPayInvoices: boolean;
  documentSigner?: Person;
}

export interface StripeIntegration {
  id: string;
  accountId: string;
  isEnabled: boolean;
  accountLinkUrl: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface CrmAccount {
  id: string;
  name: string;
  crmType: CrmIntegrationType;
}

export interface CrmOpportunity {
  id: string;
  name: string;
}

export interface LinkedCrmAccount {
  crmId: string;
}

export interface LinkedCrmOpportunity {
  crmId: string;
}

export enum BankRegion {
  DOMESTIC = 'DOMESTIC',
  INTERNATIONAL = 'INTERNATIONAL',
}

export const BankRegionLabels = {
  DOMESTIC: 'Domestic, with-in the United States',
  INTERNATIONAL: 'International, outside the United States',
};

export interface Bank {
  id?: string;
  currency: Currency;
  name: string;
  swiftCode: string;
  iban?: string;
  routingNumber: string;
  accountNumber: string;
  region?: BankRegion;
}

export interface FeatureGate {
  featureCode: FeatureCode;
  enabled: boolean;
  adminOnly: boolean;
  beta: boolean;
}

export interface Seller {
  id: string;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  legalName: string;
  website: string;
  productSet: Product[];
  hq: Address;
  nexusAddresses: Address[];
  w9?: DocumentFile;
  checkAddress?: Address;
  isBankLockbox?: boolean;
  contactDetails: Contact;
  accountsReceivableEmail: string;
  accountsPayableEmail: string;
  salesOperationsEmail: string;
  salesEmail: string;
  customerSuccess: Contact;
  terms: TextDocument;
  privacyPolicy: string;
  logoImage: string;
  logoImageDark: string;
  primaryColor: string;
  onboardingComplete: boolean;
  supportedCurrencies: Currency[];
  stripeIntegration?: StripeIntegration;
  stripeAccountId?: string;
  banks: Bank[];
  showMsaDuringCheckout?: boolean;
  showCommercialTermsDuringCheckout?: boolean;
  paymentDueUponReceipt?: number;
  crmIntegrations?: CrmIntegration[];
  financeSystemIntegrations?: FinanceSystemIntegration[];
  slackIntegration?: SlackIntegration;
  salesbricksSubscriptionId?: string;
  sellerType?: string;
  customInvoiceText?: string;
  customInternationalInvoiceText?: string;
  featureGates: FeatureGate[];
  usageLastUploaded?: Date;
  termsDocument?: TermsDocument;
  activeTermsDocuments?: TermsDocument[];
  commercialTermsDocuments?: TermsDocument[];
  forceStandardBillingSchedule?: boolean;
  fiscalYearStartMonth?: number;
  acceptedPaymentMethodsConfig: AcceptedPaymentMethodsConfig;
}

export enum CrmIntegrationType {
  'SALESFORCE' = 'SALESFORCE',
  'HUBSPOT' = 'HUBSPOT',
}

export enum CrmObjectType {
  'SALESFORCE_OPPORTUNITY' = 'Opportunity',
  'HUBSPOT_DEALS' = 'deals',
}

export enum SalesbrickObjectType {
  'ORDER' = 'ORDER',
  'BRICK' = 'BRICK',
  'PLAN' = 'PLAN',
  'PLAN_VERSION' = 'PLAN_VERSION',
  'BRICK_PRICING' = 'BRICK_PRICING',
  'ORDER_BRICK' = 'ORDER_BRICK',
}

export enum CrmObjectFieldType {
  'STRING' = 'STRING',
  'ENUM' = 'ENUM',
  'INTEGER' = 'INTEGER',
  'FLOAT' = 'FLOAT',
  'DATETIME' = 'DATETIME',
  'DATE' = 'DATE',
}

export interface CrmEnumMap {
  salesbricksValue: string;
  crmValue: string;
}

export interface CrmObjectSyncConfig {
  salesbricksObjectType?: SalesbrickObjectType;
  crmObjectType?: string;
  salesbricksObjectFieldName?: string;
  crmObjectFieldName?: string;
  crmObjectFieldType?: CrmObjectFieldType;
  crmObjectFieldLength?: number;
  enumMapping?: CrmEnumMap[];
}

export interface CrmIntegrationConfig {
  objectSyncConfigs: CrmObjectSyncConfig[];
  renewalOpportunityType: string;
  createRenewalOpportunities: Boolean;
}

export interface CrmIntegration {
  id: string;
  crmType: CrmIntegrationType;
  productSyncEnabled: boolean;
  revenueScheduleSyncEnabled: boolean;
  isEnabled: boolean;
  oauthUrl?: string;
  isSandbox: boolean;
  domain?: string;
  config: CrmIntegrationConfig;
}

export enum FinanceSystemType {
  'XERO' = 'XERO',
  'QUICKBOOKS' = 'QUICKBOOKS',
}

export interface FinanceSystemIntegration {
  financeSystemType: FinanceSystemType;
  connectionId: string;
  active: boolean;
  token?: string;
}

export interface SlackIntegration {
  id: string;
  isActive: boolean;
  config: SlackIntegrationConfig;
}

export interface SlackIntegrationConfig {
  orderStageChangeNotificationChannelId: string;
}

export enum SlackNotificationType {
  'ORDER_CLOSED_WON' = 'ORDER_CLOSED_WON',
  'ORDER_STAGE_CHANGE' = 'ORDER_STAGE_CHANGE',
  'INVOICE_PAYMENT_FAILED' = 'INVOICE_PAYMENT_FAILED',
  'INVOICE_PAID' = 'INVOICE_PAID',
  'SIGNATURE_REQUIRED' = 'SIGNATURE_REQUIRED',
  'ORDER_SHARE_LINK_OPENED' = 'ORDER_SHARE_LINK_OPENED',
  'INVOICE_PAST_DUE' = 'INVOICE_PAST_DUE',
  'MUTUAL_RENEWALS_ROLLUP' = 'MUTUAL_RENEWALS_ROLLUP',
  'AUTO_RENEWALS_ROLLUP' = 'AUTO_RENEWALS_ROLLUP',
  'PENDING_MILESTONE_ROLLUP' = 'PENDING_MILESTONE_ROLLUP',
}

export interface TextDocument {
  history: TextDocument[];
  id: string;
  name: string;
  text: string;
  description?: string;
}

export interface Contact {
  id: string;
  email: string;
  phone: string;
}

export enum OrderStage {
  building = 'BUILDING',
  //for migration
  migrating = 'MIGRATING',
  // closed types
  closed = 'CLOSED',
  closedLost = 'CLOSED_LOST',
  closedDelete = 'CLOSED_DELETE',
  // special UI only stage
  terminated = 'TERMINATED',
  all = 'ALL',
  // signing
  sellerSigning = 'SELLER_SIGNING',
}

export interface CompanyOrderStagesCount {
  stage: OrderStage;
  count: number;
}

export interface CompanyOrderTypesCount {
  orderType: OrderType;
  count: number;
}

export interface CompanyOrderCounts {
  stages: CompanyOrderStagesCount[];
  orderTypes: CompanyOrderTypesCount[];
}

export enum OrderType {
  trial = 'TRIAL',
  standard = 'STANDARD',
  renewal = 'RENEWAL',
  recast = 'RECAST',
  upgrade = 'UPGRADE',
}

export enum DealStructure {
  standard = 'STANDARD',
  ramping = 'RAMPING',
}

export enum BillingSchedule {
  'monthly' = 'MONTHLY',
  'quarterly' = 'QUARTERLY',
  'semiAnnually' = 'SEMI_ANNUALLY',
  'annually' = 'ANNUALLY',
  'allUpfront' = 'ALL_UPFRONT',
  'flexible' = 'FLEXIBLE',
}

export interface FlexibleBillingSchedule {
  date: Date;
  amount: number;
  monthDiffs: number;
}

export enum SKUTier {
  'primary' = 'PRIMARY',
  'secondary' = 'SECONDARY',
}

export enum SKUType {
  'software' = 'SOFTWARE',
  'service' = 'SERVICE',
}

export enum PricingTierSystemType {
  'flatRate' = 'FLAT_RATE',
  'tiered' = 'TIERED',
  'volumeDiscount' = 'VOLUME_DISCOUNT',
  'block' = 'BLOCK',
}

export enum DisplayBillingType {
  'oneTime' = 'ONE_TIME',
  'usage' = 'USAGE',
  'monthly' = 'MONTHLY',
  'annual' = 'ANNUAL',
  'annualAmortizedMonthly' = 'ANNUAL_AMORTIZED_MONTHLY',
  'annualAmortizedDaily' = 'ANNUAL_AMORTIZED_DAILY',
  'annualAmortizedHourly' = 'ANNUAL_AMORTIZED_HOURLY',
}

export enum AllowedBillingScheduleType {
  'monthly' = 'MONTHLY',
  'annually' = 'ANNUALLY',
  'monthlyAndAnnually' = 'MONTHLY_AND_ANNUALLY',
}

// When updating this, ensure the types in spearhead/main/utils/model_utils.py
// for CompanyRoleTypes concord
export enum CompanyRoleType {
  chiefExecutiveOfficer = 'CHIEF_EXECUTIVE_OFFICER',
  chiefOperatingOfficer = 'CHIEF_OPERATING_OFFICER',
  chiefRevenueOfficer = 'CHIEF_REVENUE_OFFICER',
  headOfSales = 'HEAD_OF_SALES',
  salesManagement = 'SALES_MANAGEMENT',
  salesRepresentative = 'SALES_REPRESENTATIVE',
  operator = 'OPERATOR',
  finance = 'FINANCE',
  legal = 'LEGAL',
  unassigned = 'UNASSIGNED',
}
export enum NewOrderCustomerOptions {
  'NEW' = 'NEW',
  'EXISTING' = 'EXISTING',
  'MIGRATING' = 'MIGRATING',
}

export interface EmploymentRole {
  id: string;
  role: string;
}

export interface Order {
  id: string;
  code?: string;
  subscriptionId: string;
  autoRenews: boolean;
  agreementNumber: number;
  previousAgreement?: Order;
  isPlatformOrder?: boolean;
  signedOrderForm?: string;
  owner?: User;
  alive: boolean;
  seller: Seller;
  stage: OrderStage;
  orderType: OrderType;
  documentsGeneratedAt: Date;
  closedAt: Date;
  startsAt: Date;
  endsAt: Date;
  createdAt: Date;
  updatedAt: Date;
  buyer: Company;
  description?: string;
  primaryUser: Person;
  signatoryUser: Person;
  signatoryUserSignature?: Signature;
  sellerSigner: User;
  sellerSignature?: Signature;
  purchaseOrder?: PurchaseOrder;
  discount?: Discount;
  parentOrder?: Order;
  locked: boolean;
  terms?: string;
  termsAtClose?: TextDocument;
  history: Order[];
  agreements: Order[];
  transactionMethod: TransactionMethod;
  productName?: string;
  terminatedAt?: Date;
  billingSchedule?: BillingSchedule;
  pricing?: Pricing;
  metadata?: SimpleKV;
  lineItems: OrderBrick[];
  statementOfWork?: string;
  paymentDueUponReceipt?: number;
  showMsaDuringCheckout?: boolean;
  showCommercialTermsDuringCheckout?: boolean;
  rampingConfig: RampingWindow[];
  crmAccount?: LinkedCrmAccount;
  crmOpportunity?: LinkedCrmOpportunity;
  fsCustomer?: FsCustomer;
  invoiceSet: [Invoice];
  accountsPayableContacts?: APContact[];
  currency: Currency;
  validStartDateRange: [Date, Date];
  termsDocument?: TermsDocument;
  commercialTermsDocument?: TermsDocument;
  forceStandardBillingSchedule?: boolean;
  buyerSubscriptionManagementUrl?: string;
}

export interface Trial {
  id: string;
  subscriptionId: string;
  stage: OrderStage;
  startsAt: Date;
  endsAt: Date;
  terminatedAt: Date;
  entitlements: TrialEntitlement[];
  buyer: TrialBuyer;
  primaryUser: TrialPrimaryUser;
  metadata?: SimpleKV;
}

export interface TrialEntitlement {
  id: string;
  name: string;
  quantity: number;
}

export interface TrialBuyer {
  id: string;
  name: string;
}

export interface TrialPrimaryUser {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
}

export interface APContact {
  email: string;
}

export interface PurchaseOrder {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  code?: string;
  uploadedFile?: string;
}

export interface DiscountLineItem {
  brickId: string;
  salesRate: PercentageDecimalNumber;
  oneTimeRate: PercentageDecimalNumber;
  oneTimeFixed: MoneyCents;
}

export interface Discount {
  expiresAt: Date;
  isExpired?: boolean;
  salesRate?: PercentageDecimalNumber;
  oneTimeRate?: PercentageDecimalNumber;
  oneTimeFixed?: MoneyCents;
  minimumSpend?: MoneyCents;
  lineItems?: DiscountLineItem[];
  planCredits?: MoneyCents;
  changeCreditsReason?: string;
}

export interface Address {
  id: string;
  line1: string;
  line2: string;
  city: string;
  region: string;
  country: string;
  zip: string;
}

export interface SimpleKV {
  [key: string]: any;
}

export interface EmailTemplate {
  name: string;
  title: string;
  autoSend: boolean;
}

export interface ContractPeriod {
  startsAt: Date;
  endsAt: Date;
  numDays: number;
  numMonthsInYear: number;
  numMonths: number;
  numDaysInPeriod: number;
  periodIdx: number;
  // GOD-1090: numMonthsInPeriod exists in pricing.contractPeriods[x].contractPeriod
  numMonthsInPeriod?: number;
}

export interface Customer {
  company: Company;
  startsAt: Date;
  endsAt: Date;
  orders: Order[];
  currentSpend: MoneyCents;
  lastTerminatedAt?: Date;
}

export interface CustomerActivity {
  timestamp: Date;
  activitySource: string;
  message: string;
}

export type AgreementType = 'Initial' | 'Renewal' | 'Upgrade' | 'Recast';

// GOD-5132: this is *not* aligned with OrderTransactionTypes
export enum TransactionMethod {
  'card' = 'STRIPE',
  'orderForm' = 'ORDER_FORM',
  'ach' = 'ACH',
  'check' = 'CHECK',
}

export enum OrderTransactionType {
  'orderForm' = 'ORDER_FORM',
  'stripe' = 'STRIPE',
}

export enum PaymentMethod {
  'creditCard' = 'CREDIT_CARD',
  'ach' = 'ACH',
  'check' = 'CHECK',
  'adjustment' = 'ADJUSTMENT',
  'wire' = 'WIRE',
}

export interface PricingLineItem {
  skuId: string;
  brickId: string;
  brickType: BrickType;
  name: string;
  planName: string;
  customUnitText: string;
  skuSchedule: SKUScheduleType;
  unitPrice: MoneyDecimal;
  oneTimePrice: MoneyCents;
  quantity: QuantityNumber;
  includedQuantity: QuantityNumber;
  subTotal: MoneyCents;
  grandTotal: MoneyCents;
  invoiceGrandTotal: MoneyCents;
  salesDiscount: MoneyCents;
  oneTimeDiscount: MoneyCents;
  totalDiscount: MoneyCents;
  tax: MoneyCents;
  ramping: boolean;
  partial: boolean;
  startsAt: Date;
  endsAt: Date;
  planCredits: MoneyCents;
  overagePrice: MoneyCents;
  preCommitment: QuantityNumber;
  netRate: MoneyDecimal;
  previousPreCommitment: QuantityNumber;
  remainingPreCommitment: QuantityNumber;
  isPreCommitment: boolean;
  ordering: number;
  tiersBreakdown?: TiersBreakdown[];
}

export interface TiersBreakdown {
  tierIdx: number;
  contractPeriod: ContractPeriod;
  quantity: number;
  unitPrice: number;
  total: number;
}

/**
 * This is a frontend-only type
 * for presenting data in the cost table component.
 */
export interface CostTableLineItem extends PricingLineItem {
  costTableNetRate: string | number;
  costTableQuantity: string | number;
  orderBrick?: ChosenBrick;
}

export interface ContractPeriodPricing {
  lineItems: PricingLineItem[];
  contractPeriod: ContractPeriod;
  unitPrice: MoneyDecimal;
  oneTimePrice: MoneyCents;
  subTotal: MoneyCents;
  grandTotal: MoneyCents;
  invoiceGrandTotal: MoneyCents;
  planCredits: MoneyCents;
  salesDiscount: MoneyCents;
  oneTimeDiscount: MoneyCents;
  totalDiscount: MoneyCents;
  tax: MoneyCents;
  currency: Currency;
  overagePrice: MoneyDecimal;
}

export interface Pricing {
  lineItems: PricingLineItem[];
  contractPeriods: ContractPeriodPricing[];
  unitPrice: MoneyDecimal;
  oneTimePrice: MoneyCents;
  subTotal: MoneyCents;
  grandTotal: MoneyCents;
  planCredits: MoneyCents;
  unusedPlanCredits: MoneyCents;
  salesDiscount: MoneyCents;
  oneTimeDiscount: MoneyCents;
  totalDiscount: MoneyCents;
  tax: MoneyCents;
  taxLabel: string;
  renewalValue: MoneyCents;
  subscriptionGrandTotal: MoneyCents;
  currency: Currency;
  arr?: MoneyCents;
  nrr?: MoneyCents;
  overagePrice: MoneyCents;
  orderId?: string;
}

export interface SubscriptionToken {
  id: number;
  expiresAt: Date;
  buyer: Company;
}

export enum BlogEntryTag {
  blog = 'BLOG',
  podcast = 'PODCAST',
}

export interface BlogEntry {
  id: string;
  title: string;
  entryText: string;
  createdAt: Date;
  updatedAt: Date;
  owner: User;
  tag: BlogEntryTag;
  preview: string;
}

export enum WebhookType {
  'ORDER_COMPLETE' = 'ORDER_COMPLETE',
  'ORDER_STAGE_CHANGE' = 'ORDER_STAGE_CHANGE',
  'ORDER_END' = 'ORDER_END',
  'ORDER_CLOSED_LOST' = 'ORDER_CLOSED_LOST',
  'ORDER_CLOSED_DELETE' = 'ORDER_CLOSED_DELETE',
  'INVOICE_START' = 'INVOICE_START',
  'INVOICE_PAST_DUE' = 'INVOICE_PAST_DUE',
  'INVOICE_PAYMENT_FAILED' = 'INVOICE_PAYMENT_FAILED',
  'INVOICE_PAYMENT_SUCCEEDED' = 'INVOICE_PAYMENT_SUCCEEDED',
  'TRIAL_CREATED' = 'TRIAL_CREATED',
  'TRIAL_CLOSED' = 'TRIAL_CLOSED',
  'TRIAL_EXTENDED' = 'TRIAL_EXTENDED',
  'TRIAL_TERMINATED' = 'TRIAL_TERMINATED',
  'TRIAL_CONVERTED' = 'TRIAL_CONVERTED',
  'TRIAL_AUTO_CONVERT_ERROR' = 'TRIAL_AUTO_CONVERT_ERROR',
}

export interface Webhook {
  id: string;
  active: boolean;
  webhookUrl: string;
  webhookType: WebhookType;
  name: string;
  example: string;
}

export interface WebhookAvailable {
  name: string;
  example: string;
  type: string;
}

export interface DocumentFile {
  filename: string;
  url: string;
}

export interface Invoice {
  id: string;
  runCalculateAt: Date;
  startsAt: Date;
  endsAt: Date;
  dueDays: number;
  calculatedAt: Date;
  generatedAt: Date;
  dueAt: Date;
  invoiceNumber: string;
  amount: MoneyCents;
  error?: string;
  paymentMethod: PaymentMethod;
  generated?: DocumentFile;
  isMuted: boolean;
  order: Order;
  pricing: ContractPeriodPricing;
  remainingAmount: MoneyCents;
  paidAt: Date;
  payments: Payment[];
}

export type BillStatementPayment = Pick<
  Payment,
  | 'id'
  | 'status'
  | 'paymentMethod'
  | 'paidAmount'
  | 'paidAt'
  | 'memo'
  | 'stripePaymentIntentId'
  | 'error'
  | 'receipt'
  | 'createdAt'
>;

export type BillStatementPayments = Array<BillStatementPayment>;

export type BillStatementInvoice = Pick<
  Invoice,
  | 'id'
  | 'startsAt'
  | 'endsAt'
  | 'calculatedAt'
  | 'runCalculateAt'
  | 'generatedAt'
  | 'dueAt'
  | 'invoiceNumber'
  | 'amount'
  | 'error'
  | 'paymentMethod'
  | 'generated'
  | 'isMuted'
  | 'pricing'
  | 'remainingAmount'
  | 'paidAt'
> & {
  payments: BillStatementPayments;
} & {
  order: Pick<Order, 'currency'>;
};

export type BillStatementPaymentSummary = Pick<
  Payment,
  | 'id'
  | 'paymentMethod'
  | 'status'
  | 'paidAmount'
  | 'paidAt'
  | 'memo'
  | 'createdAt'
>;

export type CustomerInvoice = Pick<
  Invoice,
  | 'id'
  | 'calculatedAt'
  | 'generatedAt'
  | 'paidAt'
  | 'paymentMethod'
  | 'startsAt'
  | 'endsAt'
  | 'dueAt'
  | 'amount'
  | 'remainingAmount'
  | 'runCalculateAt'
  | 'isMuted'
> & {
  payments: Array<BillStatementPaymentSummary>;
} & {
  order: Pick<Order, 'currency'>;
};

export enum PaymentStatus {
  'FAILED' = 'FAILED',
  'SUCCESS' = 'SUCCESS',
  'PROCESSING' = 'PROCESSING',
}

export interface Payment {
  id: string;
  status: PaymentStatus;
  invoice: Invoice;
  paymentMethod: PaymentMethod;
  paidAmount: MoneyCents;
  paidAt: Date;
  error?: string;
  stripePaymentIntentId?: string;
  paymentProof?: string;
  paymentProofImage?: DocumentFile;
  memo?: string | null;
  createdAt: Date;
  receipt?: DocumentFile;
  processorErrorCode?: string;
  stripePaymentMethodId?: string;
}

export interface APContact {
  email: string;
}

export interface PlanDraft {
  name: string;
  skuDefinition?: string;
  summary?: string;
  isPrivate: boolean;
  allowedBillingSchedule: AllowedBillingScheduleType;
  code?: string;
  pricePostscript: string;
  displayBillingType: DisplayBillingType;
  trialSettings?: TrialSettings;
}

export interface Plan extends PlanDraft {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  deletedAt: Date;
  product: Product;
  latestVersion: PlanVersion;
  draft: PlanDraft;
  draftVersion: PlanVersion;
  draftBrickConfig: BrickConfig[];
  featureSet: SKUFeature[];
  draftTrialSettings?: TrialSettings;
}

export interface TrialSettings {
  defaultContractLength: number;
  autoConvert: boolean;
  creditCardRequired: boolean;
}

export enum BrickType {
  'addon' = 'ADDON',
  'plan' = 'PLAN',
  'included' = 'INCLUDED',
}

export interface BrickConfig {
  brickId: string;
  brickPricingId: string;
  brickType: BrickType;
  isPrivate: boolean;
  isDeferred: boolean;
  order?: number;
}

export interface PlanVersion {
  id: string;
  version: string;
  createdAt: Date;
  plan: Plan;
  brickConfig: BrickConfig[];
  planBrick: BrickPricing;
  inferredPlanBrick: BrickPricing;
  includedBricks: BrickPricing[];
  addonBricks: BrickPricing[];
  deferredBricks: Brick[];
  deprecatedAt?: Date;
  trialSettings?: TrialSettings;
}

export interface Brick {
  id: string;
  name: string;
  code?: string;
  skuType: SKUType;
  skuDefinition?: string;
  createdAt: Date;
  updatedAt: Date;
  seller?: Seller;
  summary: string;
  cogs?: number;
  isOneTimePurchase?: boolean;
  skuSchedule: SKUScheduleType;
  usagePriceCalculation: UsagePriceCalculationType;
  usageFrequency: UsageFrequencyType;
  customUnitText?: string;
  deletedAt?: Date;
}

export interface BrickPricingTier {
  priceMonthly: MoneyCents;
  priceAnnual: MoneyCents;
  priceOneTime?: MoneyCents;
  priceUsage: MoneyDecimal;
  pricePreCommitment?: MoneyDecimal;
  lowerBound: QuantityNumber;
  percentage?: PercentageDecimalNumber;
  percentagePriceMin?: MoneyCents;
}

export enum Currency {
  usd = 'USD',
  eur = 'EUR',
  gbp = 'GBP',
  aud = 'AUD',
  cad = 'CAD',
}

export enum PreCommitmentOverageMethod {
  noOverage = 'NO_OVERAGE',
  multiplier = 'MULTIPLIER',
  flatRate = 'FLAT_RATE',
  suspend = 'SUSPEND',
}

export enum BrickPricingType {
  monetary = 'MONETARY',
  percentage = 'PERCENTAGE',
}

export type CurrencyPricingTiers = Partial<
  Record<Currency, BrickPricingTier[]>
>;

export interface BrickPricing {
  id: string;
  version: string;
  createdAt: Date;
  brick: Brick;
  priceType: BrickPricingType;
  calculatePercentageOnDiscountedTotal?: boolean;
  percentageBaseBrickIds?: string[];
  tiers: CurrencyPricingTiers;
  pricingTierSystem: PricingTierSystemType;
  maxUnits?: QuantityNumber;
  minUnits?: QuantityNumber;
  includedUnits?: QuantityNumber;
  isOneTimePurchase?: boolean;
  missingCurrencies?: Currency[];
  overageMethod?: PreCommitmentOverageMethod;
  overageValue?: MoneyDecimal;
  // frontend only field used to check if we need to
  // display custom pricing instead of original price in order builder
  hasCustomPricing?: boolean;
}

export interface BrickPricingCurrencyInput {
  currency: Currency;
  tiers: BrickPricingTier[];
}

export interface BrickPricingInput {
  tiers: BrickPricingCurrencyInput[];
  pricingTierSystem: PricingTierSystemType;
  priceType: BrickPricingType;
  calculatePercentageOnDiscountedTotal?: boolean;
  percentageBaseBrickIds?: string[];
  brickId?: string;
  maxUnits?: QuantityNumber;
  minUnits?: QuantityNumber;
  includedUnits?: QuantityNumber;
}

export interface CreateBrickPricingInput extends BrickPricingInput {
  brickId: string;
  hasCustomPricing?: boolean;
}

export interface OrderBrick {
  id: string;
  order: Order;
  planVersion: PlanVersion;
  brickPricing: BrickPricing;
  quantity: QuantityNumber;
  addedAt: Date;
  removedAt?: Date;
  preCommitment?: QuantityNumber;
  overageMethod?: PreCommitmentOverageMethod;
  overageValue?: MoneyDecimal;
}

export enum InvoicePaymentMethod {
  creditCard = 'CREDIT_CARD',
  achDebit = 'ACH_DEBIT',
  check = 'CHECK',
}

// supported CC brands from Stripe API spec
export enum CreditCardBrand {
  visa = 'VISA',
  amex = 'AMERICAN EXPRESS',
  diners = 'DINERS',
  discover = 'DISCOVER',
  jcb = 'JCB',
  mastercard = 'MASTERCARD',
  unionpay = 'UNIONPAY',
  unknown = 'UNKNOWN PAYMENT METHOD',
}

export interface StripeCreditCard {
  id: string;
  brand: CreditCardBrand;
  last4: string;
  isDefault?: boolean;
}

// graphql and maps suck, so we transform for ease of use
export type RampingConfig = Record<string, RampingWindow[]>;

export interface RampingWindow {
  brickPricingId: string;
  brickId?: string;
  startsAt: Date;
  quantity: QuantityNumber;
}

export interface Signature {
  id: string;
  // base 64 of an image
  signature: string;
  order?: Order;
  email: string;
  createdAt: Date;
}

export interface BrickAnalytics {
  id: string;
  planCount: number;
  openOrdersCount: number;
  activeSubscriptionsCount: number;
  lastTermDate: Date;
}

export interface BrickWarning {
  plan: Plan;
  brickPricing: BrickPricing;
  missingCurrencies: Currency[];
}

export interface ObjectFieldEnum {
  label: string;
  value: string;
}

export interface SalesbricksObjectFieldEnumType {
  name: string;
  enum: ObjectFieldEnum[];
}

export interface CrmIntegrationField {
  salesbricksObjectType: SalesbrickObjectType;
  salesbricksObjectFieldNames: string[];
  salesbricksObjectFieldEnums: SalesbricksObjectFieldEnumType[];
  crmObjectType: string;
  crmObjectFields: CrmObjectFieldSpec[];
}

export interface CrmObjectFieldSpec {
  name: string;
  label: string;
  crmDataType: CrmObjectFieldType;
  crmDataLength: number;
  crmDataEnum: ObjectFieldEnum[];
}

export interface SlackChannel {
  id: string;
  name: string;
  isPrivate: boolean;
}

export enum IntegrationLevels {
  BASIC = 'BASIC',
  PRODUCT = 'PRODUCT',
  REVENUE = 'REVENUE',
}

export enum DocType {
  ORDER_FORM = 'Order Form',
  SERVICE_ORDER = 'Service Order',
  SALES_ORDER = 'Sales Order',
  CUSTOM_TYPE = 'Custom Type',
}

export enum RevenueChannelType {
  ALL = 'ALL',
  SELF_SERVICE = 'SELF_SERVICE',
  SALES_LED = 'SALES_LED',
}

export enum RevenueStageType {
  EVALUATING = 'EVALUATING',
  FREE = 'FREE',
  PAYING = 'PAYING',
  CHURNED = 'CHURNED',
  LAPSED = 'LAPSED',
}

export enum RenewalTerms {
  AUTOMATIC = 'AUTOMATIC',
  MUTUAL = 'MUTUAL',
}

export enum TermDocumentType {
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  COMMERCIAL = 'COMMERCIAL_TERMS',
}

export enum TermAndConditionType {
  LINK = 'Link',
  CUSTOM = 'Custom',
}

export enum LiteOrdersOccurrence {
  ANNUAL = 'ANNUAL',
  MONTHLY = 'MONTHLY',
  ONE_TIME = 'ONE_TIME',
}

export const LineItemOccurrenceLabels = {
  [LiteOrdersOccurrence.ANNUAL]: '/ year',
  [LiteOrdersOccurrence.MONTHLY]: '/ month',
  [LiteOrdersOccurrence.ONE_TIME]: 'one time',
};

export enum SellerType {
  LITE = 'LITE',
  PAID = 'PAID',
}

export enum InvalidActivationLinkMessageType {
  invalid = 'INVALID',
  already_activated = 'ALREADY_ACTIVATED',
}

export type SubscriptionMap = Record<Order['subscriptionId'], Order[]>;

export enum SubscriptionTerminationTypes {
  onRenewal = 'ON_RENEWAL',
  scheduled = 'SCHEDULED',
}

export interface RedshiftEvent {
  id: string;
  channel?: string;
  originalTimestamp?: Date;
  sessionId?: string;
  user?: Person;
  activityDescription?: string;
  properties?: Record<string, any>;
  context?: Record<string, any>;
  order?: Order;
  event?: Event;
}

export interface RedshiftGeoLocation {
  ip?: string;
  countryCode?: string;
  cityName?: string;
}

export interface RedshiftEventSession {
  sessionId: string;
  events: Event[];
  email: string;
  latestTimestamp: Date;
  geoLocation: RedshiftGeoLocation;
  channel: string;
}

// when dealing with usage we cap fractional cents to this many decimal places
export const USAGE_DECIMAL_LIMIT = 6;

// the default payment terms (currently referred as payment_due_upon_receipt/paymentDueUponReceipt)
// for a new order (also referenced in spearhead/seller/models)
export const DEFAULT_PAYMENT_TERMS = 30;

export interface ConversightRole {
  token: string;
  datasetId: string;
}

export interface TermsDocument {
  id?: string;
  isDefault?: boolean;
  name: string;
  redlined?: boolean;
  renewalTerms?: RenewalTerms;
  termsDoc?: DocumentFile;
  documentType?: string;
  createdAt?: Date;
  parentId?: string;
}
export interface MilestonePayment {
  id: string;
  orderBrick: OrderBrick;
  invoice: Invoice;
  createdAt: Date;
  updatedAt: Date;
  quantity: number;
}

export interface SubscriptionMilestone {
  id: string;
  orderBrick: OrderBrick;
  milestonePayments: MilestonePayment[];
  nextInvoice: Invoice;
}

export type GetLayoutType<P> = (page: ReactElement, pageProps: P) => ReactNode;

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: GetLayoutType<P>;
};

export type AppPropsWithLayout = AppProps & {
  children?: React.ReactNode;
  Component: NextPageWithLayout;
};

export type PageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: GetLayoutType<P>;
};

export interface UseDataHookReturn<T> {
  data: T;
  error: ApolloError;
  loading: boolean;
  noData: boolean;
}

export interface StyleModule {
  readonly [key: string]: string;
}

export enum SubscriptionTabs {
  DETAILS = 'Details',
  HISTORY = 'Order history',
  STATEMENTS = 'Statements',
  PAYMENTS = 'Payments',
}

export interface ReceiptInfo {
  url: string;
  paymentId: string;
}

export enum UpdateTrialMethod {
  extend = 'Extend',
  convert = 'Convert',
  terminate = 'Terminate',
}

export interface CompanyOrders {
  count: number;
  orders: Order[];
}

export interface AcceptedPaymentMethods {
  orderForm: boolean;
  creditCard: boolean;
  ach: boolean;
}

export interface AcceptedPaymentMethodsConfig {
  paywallCreditCard: boolean;
  paywallAch: boolean;
  paywallCheck: boolean;
  checkoutCreditCard: boolean;
  checkoutAch: boolean;
  checkoutOrderForm: boolean;
  minimumOrderFormTcv: MoneyCents;
}

type ChartRawDataEntry<T = Record<string, any>> = {
  value: number;
  bucket?: string;
  metaData: T;
};
export type ChartRawData<T = Record<string, any>> = {
  label: string;
  data: ChartRawDataEntry<T>[];
};

export type RenewalChartMetaData = {
  renewalCustomerBreakdown: {
    value: number;
    buyerName: string;
  }[];
};

export type InvoicingAndPaymentsChartMetaData = {
  invoiceReceivables: {
    count: number;
  };
};

export type RenewalChartRawData = ChartRawData<RenewalChartMetaData>;
export type InvoiceAndPaymentsChartRawData =
  ChartRawData<InvoicingAndPaymentsChartMetaData>;

export enum ChartValuesFormat {
  currency = 'CURRENCY',
  numeric = 'NUMERIC',
}

export interface FsIsActive {
  exists: boolean;
  isActive: boolean;
}

export interface FsCustomer {
  id: string;
  name: string;
}

export interface InvoiceDaysPastDueFilter {
  notDue: boolean;
  days1To30: boolean;
  days31To60: boolean;
  days61To90: boolean;
  days91To120: boolean;
  daysOver120: boolean;
}

export type SelectOptionItem = {
  label: any;
  value: any;
};

export enum CustomDomainStatus {
  ACTIVE = 'ACTIVE',
  CONFIGURING = 'CONFIGURING',
  DISCONNECTED = 'DISCONNECTED',
}

export type SelectOptions = SelectOptionItem[];

export interface CustomDomain {
  id: string;
  domainAuthentication: DomainAuthentication;
  domain: string;
  billingEmailLocalPart: string;
  salesEmailLocalPart: string;
  status: CustomDomainStatus;
  lastCheckedAt: Date;
}

export interface DomainAuthentication {
  domain: string;
  valid: boolean;
  dns: DNS;
}

export interface DNS {
  spf: DNSRecord;
  dkim1: DNSRecord;
  dkim2: DNSRecord;
  mailCname: DNSRecord;
}

export interface DNSRecord {
  host: string;
  type: string;
  data: string;
  valid: boolean;
}

export enum OmniResultType {
  order = 'ORDER',
  invoice = 'INVOICE',
  customer = 'CUSTOMER',
  teleport = 'TELEPORT',
}

export interface OmniResultAction {}

export type OmniResultActionTeleport = {
  url: string;
};

export type OmniResultActionOrder = {
  url: string;
  customer: string;
  plan: string;
  startsAt: Date;
  grandTotal: number;
};

export type OmniResultActionInvoice = {
  url: string;
  customer: string;
  invoiceNumber: string;
  dueAt: Date;
  status: string;
};

export type OmniResultActionCustomer = {
  url: string;
};

export interface OmniResultAction {}

export interface OmniResult {
  name: string;
  resultType: OmniResultType;
  action: OmniResultAction;
  // internal state for provider
  index: number;
}
export enum EmailCategoryTypes {
  billing = 'BILLING',
  sales = 'SALES',
}

export enum EmailType {
  staffAlert = 'STAFF_ALERT',
  sellerAccountActivation = 'SELLER_ACCOUNT_ACTIVATION',
  sellerPasswordReset = 'SELLER_PASSWORD_RESET',
  sellerOrderStageChange = 'SELLER_ORDER_STAGE_CHANGE',
  sellerPlatformWelcome = 'SELLER_PLATFORM_WELCOME',
  sellerTerminateSubscription = 'SELLER_TERMINATE_SUBSCRIPTION',
  buyerOrderCompleteOrderForm = 'BUYER_ORDER_COMPLETE_ORDER_FORM',
  buyerOrderCompleteElectronic = 'BUYER_ORDER_COMPLETE_ELECTRONIC',
  buyerInvoicePastDue = 'BUYER_INVOICE_PAST_DUE',
  sellerInvoicePastDue = 'SELLER_INVOICE_PAST_DUE',
  buyerShareOrder = 'BUYER_SHARE_ORDER',
  sellerShareOrder = 'SELLER_SHARE_ORDER',
  buyerPaymentAchFailed = 'BUYER_PAYMENT_ACH_FAILED',
  buyerPaymentAchProcessed = 'BUYER_PAYMENT_ACH_PROCESSED',
  buyerPaymentAchProcessing = 'BUYER_PAYMENT_ACH_PROCESSING',
  buyerCcUpcomingExpiration = 'BUYER_CC_UPCOMING_EXPIRATION',
  buyerPaymentCcFailed = 'BUYER_PAYMENT_CC_FAILED',
  buyerPaymentCcProcessed = 'BUYER_PAYMENT_CC_PROCESSED',
  buyerPaymentManualProcessed = 'BUYER_PAYMENT_MANUAL_PROCESSED',
  buyerInvoiceAdjustment = 'BUYER_INVOICE_ADJUSTMENT',
  buyerInvoiceStart = 'BUYER_INVOICE_START',
  buyerRenewalAchFailed = 'BUYER_RENEWAL_ACH_FAILED',
  buyerRenewalAchProcessed = 'BUYER_RENEWAL_ACH_PROCESSED',
  buyerRenewalAchProcessing = 'BUYER_RENEWAL_ACH_PROCESSING',
  buyerRenewalCcSuccessful = 'BUYER_RENEWAL_CC_SUCCESSFUL',
  buyerRenewalCcFailed = 'BUYER_RENEWAL_CC_FAILED',
  buyerRenewalOrderFormSuccessful = 'BUYER_RENEWAL_ORDER_FORM_SUCCESSFUL',
  sellerPaymentAchFailed = 'SELLER_PAYMENT_ACH_FAILED',
  sellerPaymentAchProcessed = 'SELLER_PAYMENT_ACH_PROCESSED',
  sellerPaymentAchProcessing = 'SELLER_PAYMENT_ACH_PROCESSING',
  sellerPaymentCcFailed = 'SELLER_PAYMENT_CC_FAILED',
  sellerPaymentCcProcessed = 'SELLER_PAYMENT_CC_PROCESSED',
  sellerPaymentManualProcessed = 'SELLER_PAYMENT_MANUAL_PROCESSED',
  sellerInvoiceAdjustment = 'SELLER_INVOICE_ADJUSTMENT',
  sellerInvoiceStart = 'SELLER_INVOICE_START',
  buyerSignatureRequired = 'BUYER_SIGNATURE_REQUIRED',
  sellerSignatureRequired = 'SELLER_SIGNATURE_REQUIRED',
  buyerRenewal = 'BUYER_RENEWAL',
  sellerRenewal = 'SELLER_RENEWAL',
  sellerApRenewal = 'SELLER_AP_RENEWAL',
}

export interface NotificationSetting {
  name: string;
  group: string;
  emailType: EmailType;
  eventTrigger: string;
  documentRefUri: string;
  isMuted: boolean;
}
