import React from 'react';
import styles from './tag.module.scss';
import {AgreementType, Order, OrderStage, OrderType} from 'types';
import {displayableOrderStages} from 'utils/orderUtils';

export type TagSize = 'normal' | 'large' | 'small';
export type TagColor =
  | 'normal'
  | 'warning'
  | 'error'
  | 'terminated'
  | 'success'
  | 'info'
  | 'inactive'
  | 'notify';

const Tag = (props: {
  label: string | React.ReactElement;
  tagColor?: TagColor;
  tagSize?: TagSize;
  className?: string;
  'data-test'?: string;
}) => {
  const {label, tagColor = 'normal', tagSize = 'normal', className} = props;

  const tagClassNames = [styles.tag, className];

  tagClassNames.push(styles[`${tagColor}-color`]);
  tagClassNames.push(styles[`${tagSize}-size`]);

  return (
    <p data-test={props['data-test']} className={tagClassNames.join(' ')}>
      {label}
    </p>
  );
};

function tagColorForStage(stage: OrderStage): TagColor {
  if ([OrderStage.closed].includes(stage)) {
    return 'success';
  }
  if ([OrderStage.closedLost].includes(stage)) {
    return 'error';
  }
  if ([OrderStage.terminated, OrderStage.closedDelete].includes(stage)) {
    return 'terminated';
  }

  return 'warning';
}

// TODO find out what colors are supposed to apply to each tag type
function tagColorForType(type: AgreementType): TagColor {
  if (['Renewal', 'Upgrade'].includes(type)) {
    return 'info';
  }
  return 'info';
}

export const OrderStageTag = (props: {order: Order}) => {
  const {order} = props;
  if (order.terminatedAt) {
    order.stage = OrderStage.terminated;
  }
  const label = displayableOrderStages[order.stage];
  const tagColor = tagColorForStage(order.stage);
  return <Tag label={label} tagColor={tagColor} tagSize={'normal'} />;
};

export const OrderTypeTag = (props: {order: Order}) => {
  const {order} = props;
  const agreementIndex = order.agreements.findIndex(
    (agreement) => agreement.id === order.id,
  );

  let orderType: AgreementType = 'Upgrade';

  if (agreementIndex === 0 || !order.agreements?.length) {
    orderType = 'Initial';
  } else if (order.orderType === OrderType.renewal) {
    orderType = 'Renewal';
  } else if (order.orderType === OrderType.recast) {
    orderType = 'Recast';
  }

  const tagColor = tagColorForType(orderType);

  return <Tag label={orderType} tagColor={tagColor} tagSize={'large'} />;
};

export default Tag;
