import {
  BillingSchedule,
  BrickConfig,
  BrickPricing,
  BrickType,
  CostTableLineItem,
  CrmIntegrationType,
  Currency,
  Discount,
  Order,
  OrderStage,
  OrderType,
  PricingLineItem,
  Product,
  SKUCategory,
  SKUScheduleType,
  TransactionMethod,
} from 'types';
import {add, endOfDay, isAfter} from 'date-fns';
import {ChosenBrick, ChosenProduct} from 'reducers/buildOrderReducer';
import {fullName} from './textFormatting';

interface CategorizedPricingLineItem {
  plans?: CostTableLineItem[];
  subscriptions?: Record<string, CostTableLineItem[]>;
  oneTime?: CostTableLineItem[];
  milestone?: CostTableLineItem[];
  preCommitted?: CostTableLineItem[];
  payAsYouGo?: CostTableLineItem[];
}

export function localStorageOrderKeyForProduct(product: Product) {
  return 'ORDER:' + product.id;
}

export function localStoragePurchaseFormKeyForOrder(orderId: Order['id']) {
  return 'PURCHASE_FORM:' + orderId;
}

export function getOrderProduct(order: Order): Product | null {
  return order?.lineItems?.[0]?.planVersion?.plan?.product ?? null;
}

export function getOrderIndexText(order: Order): string {
  const agreementIndex = order?.agreements?.findIndex(
    (agreement) => agreement.startsAt < add(order.startsAt, {days: 1}),
  );

  const agreementText = agreementIndex
    ? `${agreementIndex}`.padStart(3, '0')
    : `${order.agreements.length + 1}`.padStart(3, '0');

  return agreementText;
}

export function getLastBaseAgreement(order: Order): Order {
  const {agreements} = order;

  for (let i = 0; i < agreements.length; i++) {
    const agreement = agreements[agreements.length - (1 + i)];
    if (
      agreement.stage === OrderStage.closed &&
      agreement.orderType === OrderType.renewal
    ) {
      return agreement;
    }
  }
  return agreements[0];
}

export function getLastAgreementNumber(
  agreements: Order[],
  closedAgreementsOnly: boolean = false,
): number | null {
  if (!agreements || !agreements.length) return null;

  return agreements.reduce((n, a: Order) => {
    if (closedAgreementsOnly && a.stage != OrderStage.closed) {
      return n;
    }
    return Math.max(n, a.agreementNumber);
  }, 1);
}

// returns the last agreement of a chain
export function getLastAgreementInChain(
  agreements: Order[],
  closedAgreementsOnly: boolean = false,
): Order {
  if (!agreements || !agreements.length) return null;

  const lastAgreementNumber = getLastAgreementNumber(
    agreements,
    closedAgreementsOnly,
  );

  return agreements.find(
    (a: Order) => a.agreementNumber === lastAgreementNumber,
  );
}

export function formatBillingSchedule(bs: BillingSchedule): string {
  switch (bs) {
    case BillingSchedule.monthly:
      return 'Monthly';

    case BillingSchedule.quarterly:
      return 'Quarterly';

    case BillingSchedule.semiAnnually:
      return 'Semi-annually';

    case BillingSchedule.annually:
      return 'Annually';

    case BillingSchedule.allUpfront:
      return 'All upfront';

    case BillingSchedule.flexible:
      return 'Flexible billing';
  }
}

export function formatBillingScheduleSingular(bs: BillingSchedule): string {
  switch (bs) {
    case BillingSchedule.monthly:
      return 'Month';

    case BillingSchedule.quarterly:
      return 'Quarter';

    case BillingSchedule.semiAnnually:
      return 'Semi-annual';

    case BillingSchedule.annually:
      return 'Annual';

    case BillingSchedule.allUpfront:
      return 'All upfront';
  }
}

/**
 * A mapping of the order stage enums to human readable text that we display in-app.
 * @returns An object that maps order stage enums to strings.
 */
export const displayableOrderStages: Record<OrderStage, string> = {
  [OrderStage.all]: 'All',
  [OrderStage.building]: 'Open',
  [OrderStage.migrating]: 'Migrating',
  [OrderStage.closed]: 'Close won',
  [OrderStage.terminated]: 'Terminated',
  [OrderStage.closedLost]: 'Close loss',
  [OrderStage.closedDelete]: 'Close deleted',
  [OrderStage.sellerSigning]: 'Awaiting countersign',
};

export const displayableOrderTypes: Record<OrderType, string> = {
  [OrderType.trial]: 'Trial',
  [OrderType.recast]: 'Recast',
  [OrderType.renewal]: 'Renewal',
  [OrderType.upgrade]: 'Upgrade',
  [OrderType.standard]: 'Standard',
};

export const isDiscountValid = (
  discount: Discount,
  stage: OrderStage,
): boolean => {
  return !discount || !discount.expiresAt || stage !== OrderStage.building
    ? false
    : true;
};

export const isDiscountExpired = (discount: Discount) => {
  return (
    discount.isExpired ?? isAfter(new Date(), endOfDay(discount.expiresAt))
  );
};

export const mapBrickConfigByBrickId = (brickConfig: BrickConfig[]) => {
  return brickConfig.reduce((acc, config, idx) => {
    acc[config.brickId] = idx;
    return acc;
  }, {});
};

export const sortBrickPricingByBrickConfig = (
  brickPricings: BrickPricing[],
  brickConfig: BrickConfig[],
) => {
  const brickPricingMapping = mapBrickConfigByBrickId(brickConfig);

  return brickPricings.sort((brickA, brickB) => {
    return (
      brickPricingMapping[brickA.brick.id] -
      brickPricingMapping[brickB.brick.id]
    );
  });
};

export const sortLineItemsByBrickConfig = (
  lineItems: PricingLineItem[],
  brickConfig: BrickConfig[],
) => {
  const brickPricingMapping = mapBrickConfigByBrickId(brickConfig);
  return lineItems.sort((lineItemA, lineItemB) => {
    if (lineItemA.brickType === BrickType.plan) {
      return -1; // "PLAN" brick comes first
    }
    if (lineItemB.brickType === BrickType.plan) {
      return 1; // "PLAN" brick comes first
    }
    return (
      brickPricingMapping[lineItemA.brickId] -
      brickPricingMapping[lineItemB.brickId]
    );
  });
};

export const sortCategoryItems = (
  items: CostTableLineItem[],
  categoryName: SKUCategory,
): CostTableLineItem[] => {
  // Shallow clone to avoid mutation
  const itemsClone = [...items];
  switch (categoryName) {
    case SKUCategory.payAsYouGo:
      return itemsClone.sort((a: CostTableLineItem, b: CostTableLineItem) => {
        /*
         * If PAYGO bricks have required quantity in the Order,
         * we wanna sort by grand total (net rate x quantity).
         *
         * Otherwise, quantity would be set to 0 and we'd want
         * to sort by net price per unit, so use 1 as quantity instead.
         */
        const bQuantity = Number(b.costTableQuantity) || 1;
        const aQuantity = Number(a.costTableQuantity) || 1;

        return (
          Number(b.costTableNetRate) * bQuantity -
          Number(a.costTableNetRate) * aQuantity
        );
      });
    default:
      return itemsClone.sort(
        (a: CostTableLineItem, b: CostTableLineItem) =>
          b.grandTotal - a.grandTotal,
      );
  }
};

const getRampAggregate = (ramp: CostTableLineItem[]) => {
  const result = ramp.reduce((prev: number, curr: CostTableLineItem) => {
    return prev + curr.grandTotal;
  }, 0);

  return result;
};

export const sortSubscriptions = (items: CostTableLineItem[][]) => {
  // Sorting subscriptions is different from other bricks
  // because ramps will need to get aggregated.

  // Shallow clone to avoid mutation
  // (we sort only by first dimension)
  const itemsClone = [...items];

  return itemsClone
    .sort((a: CostTableLineItem[], b: CostTableLineItem[]) => {
      return getRampAggregate(b) - getRampAggregate(a);
    })
    .flat();
};

export const sortPartialBricksByDate = (items: CostTableLineItem[]) => {
  // Shallow clone to avoid mutation
  const itemsClone = [...items];
  return itemsClone.sort((a: CostTableLineItem, b: CostTableLineItem) => {
    return a.startsAt.getTime() - b.startsAt.getTime();
  });
};

// Categorize the linear list of items
export const categorizeTableLineItems = (
  items: CostTableLineItem[] = [],
): CategorizedPricingLineItem => {
  return items.reduce<CategorizedPricingLineItem>(
    (acc: CategorizedPricingLineItem, curr: CostTableLineItem) => {
      if (curr.brickType === BrickType.plan) {
        // plan
        return {...acc, plans: [...acc.plans, curr]};
      } else if (curr.skuSchedule === SKUScheduleType.subscription) {
        //subscription

        return {
          ...acc,
          subscriptions: {
            ...acc.subscriptions,
            [curr.skuId]: [...(acc.subscriptions[curr.skuId] ?? []), curr],
          },
        };
        // return {...acc, subscriptions: [...acc.subscriptions, curr]};
      } else if (curr.skuSchedule === SKUScheduleType.oneTime) {
        //one-time
        return {...acc, oneTime: [...acc.oneTime, curr]};
      } else if (curr.skuSchedule === SKUScheduleType.milestone) {
        // milestone
        return {...acc, milestone: [...acc.milestone, curr]};
      } else if (curr.isPreCommitment) {
        //pre-committed usage
        return {...acc, preCommitted: [...acc.preCommitted, curr]};
      } else if (
        !curr.preCommitment &&
        curr.skuSchedule === SKUScheduleType.usage
      ) {
        //pay as you go usage
        return {...acc, payAsYouGo: [...acc.payAsYouGo, curr]};
      }
      return acc;
    },
    {
      plans: [],
      subscriptions: {},
      oneTime: [],
      milestone: [],
      preCommitted: [],
      payAsYouGo: [],
    },
  );
};

export const sortAndFilterCategorizedItems = (
  categoriesOfLineItems: CategorizedPricingLineItem = {},
  orderedAndFilteredCategories?: SKUCategory[],
): CostTableLineItem[] => {
  // Create an ordered list of categories, since we cannot rely on object keys to maintain order

  const categories = orderedAndFilteredCategories
    ? orderedAndFilteredCategories
    : [
        SKUCategory.plans,
        SKUCategory.subscriptions,
        SKUCategory.oneTime,
        SKUCategory.milestone,
        SKUCategory.preCommitted,
        SKUCategory.payAsYouGo,
      ];

  return categories.reduce((prev: CostTableLineItem[], curr: SKUCategory) => {
    if (curr === SKUCategory.subscriptions) {
      // sort ramps by start date
      const rampSkuIds = Object.keys(categoriesOfLineItems[curr]);
      const sortedRamps = rampSkuIds.map((skuId: string) => {
        return sortPartialBricksByDate(categoriesOfLineItems[curr][skuId]);
      });
      const sortedSubscriptions = sortSubscriptions(sortedRamps);
      return [...prev, ...sortedSubscriptions];
    } else {
      //sort category items
      const sorted = sortCategoryItems(categoriesOfLineItems[curr], curr);
      return [...prev, ...sorted];
    }
  }, []);
};

export const sortByOrderingField = (
  items: CostTableLineItem[],
): CostTableLineItem[] => {
  return items.sort((a, b) => a.ordering - b.ordering);
};

export const convertToCostTableLineItem = (
  items: PricingLineItem[],
  selectedProducts: ChosenProduct[],
  discount: Discount,
  currency: Currency,
): CostTableLineItem[] => {
  return items.map((lineItem: PricingLineItem) => {
    const orderBrick = getOrderBrickForPricingLineItem(
      lineItem,
      selectedProducts,
    );

    return {
      ...lineItem,
      costTableNetRate: getCostTableNetRate(
        lineItem,
        orderBrick,
        discount,
        currency,
      ),
      costTableQuantity: getCostTableQuantity(lineItem, orderBrick),
      orderBrick,
    };
  });
};

export const getOrderBrickForPricingLineItem = (
  lineItem: PricingLineItem,
  selectedProducts: ChosenProduct[],
) => {
  const f = (b: ChosenBrick) =>
    b.brickPricing.id === lineItem.skuId ||
    b.brickPricing.brick.id === lineItem.brickId;
  const orderBrick = selectedProducts
    ?.find((o: ChosenProduct) => o?.bricks.some(f))
    ?.bricks.find(f);

  return orderBrick;
};

export const getCostTableNetRate = (
  lineItem: PricingLineItem,
  orderBrick: ChosenBrick,
  discount: Discount,
  currency: Currency,
) => {
  const isUsage = lineItem.skuSchedule === SKUScheduleType.usage;
  let computedNetRate = lineItem.netRate;
  if (
    isUsage &&
    orderBrick?.brickPricing?.includedUnits &&
    orderBrick?.brickPricing?.tiers?.[currency]?.length &&
    // GOD-4222: prefer preCommitment price over includedUnits price
    !orderBrick?.preCommitment
  ) {
    const totalDiscountRate = discount?.salesRate || 0;
    computedNetRate =
      orderBrick?.brickPricing?.tiers?.[currency]?.[0]?.priceUsage;
    // we should remove any sales rate discounts applied
    const totalDiscount = (totalDiscountRate / 100) * computedNetRate;
    computedNetRate = computedNetRate - totalDiscount;
  }

  return computedNetRate;
};

export const getCostTableQuantity = (
  lineItem: PricingLineItem,
  orderBrick: ChosenBrick,
) => {
  const isUsage = lineItem.skuSchedule === SKUScheduleType.usage;

  let quantity: string | number = lineItem.quantity;
  if (isUsage) {
    // assume this is pay as you go
    if (orderBrick?.preCommitment) {
      quantity = orderBrick?.preCommitment;
    } else if (orderBrick?.brickPricing?.includedUnits) {
      quantity = orderBrick?.brickPricing?.includedUnits;
    } else if (orderBrick?.brickPricing?.minUnits) {
      quantity = orderBrick?.brickPricing?.minUnits;
    } else {
      quantity = 'N/A';
    }
  }

  return quantity;
};

export function getAttributedTo(order: Order): string {
  let attributedTo = 'Self service';
  if (order.orderType === OrderType.trial) {
    attributedTo = 'Trial';
  } else if (order.owner) {
    attributedTo = fullName(order.owner);
  }
  return attributedTo;
}

export const getPlansFromPricing = (pricing) => {
  // deduped list of plan names to show. we currently only support 1 plan, but meh
  return pricing?.lineItems
    .reduce(
      (plans: string[], li: PricingLineItem) => [...plans, li.planName],
      [],
    )
    .filter((name, idx, plans) => plans.indexOf(name) === idx);
};

export function isElectronicCheckout(
  paymentMethod: TransactionMethod,
): boolean {
  return [TransactionMethod.card, TransactionMethod.ach].includes(
    paymentMethod,
  );
}

export const getCrmLabels = (crmType: CrmIntegrationType) => {
  const crmLabel = crmType === 'HUBSPOT' ? 'CRM Company' : 'CRM Account';
  const crmSubLabel = crmType === 'HUBSPOT' ? 'CRM Deal' : 'CRM Opportunity';
  return {crmLabel, crmSubLabel};
};
