export const toLocator = (i: string, contains?: boolean): string => {
  // Added option to generate wildcard matches as locators
  if (contains) {
    return `[data-test*="${i}"]`;
  }

  return `[data-test="${i}"]`;
};

export const staticTestOrders: Record<string, string> = {
  CLOSED_USAGE_ORDER: '2b0b6487-ac22-4c78-943c-b6bb79889987',
  CLOSED_PAYWALL_CC_SUCCESS_ORDER: '2dc8d8ee-c1dc-4591-a59b-a208195b0c2e',
  CLOSED_PAYWALL_CC_FAILURE_ORDER: '2dc8d8ee-c1dc-dead-daed-a208195b0c2e',
  CLOSED_PAYWALL_ACH_SUCCESS_ORDER: '2dc8d8ee-c1dc-4591-a59b-bbbbbbbbbbbb',
  CLOSED_PAYWALL_ACH_FAILURE_ORDER: '2dc8d8ee-c1dc-4591-a59c-cccccccccccc',
  BUILDING_MANUAL_CLOSE_ORDER: '0406ac5b-e6bd-575a-aff9-788c887bdb00',
  BUILDING_MANUAL_CLOSE_ORDER_WITH_PURCHASE_ORDER:
    '186c7669-919d-46d9-8883-dfdf304129c5',
  UPGRADING_MANUAL_CLOSE_ORDER: '2dc8d8ee-c1dc-dead-daed-bbb8195b0c2e',
  LOCKED_ORDER: '2dc8d8ee-c1dc-dead-daed-a208195b0c25',
  UNLOCKED_ORDER: '2dc8d8ee-c1dc-dead-daed-a208195b0c36',
  SELLER_SIGNER_ORDER: '2b0b6487-dead-beef-dead-b6bb79889987',
  SELLER_SIGNER_ORDER_FOR_REVERT: '2dc8d8ee-c1dc-dead-daed-bbb8195b0c44',
  ORDER_UPGRADE_WITH_MSA_AND_TERMS: '46454a04-95d1-51fe-9a18-4a1f7d110874',
  ORDER_UPGRADE_WITHOUT_MSA_AND_TERMS: '5a5ca80d-7114-583b-8fcf-70461fef32d4',
  SELLER_OWNER_BILLING_SCHEDULE: '74b30343-3341-4a27-b7bb-8718cd0d77e6',
  EXPIRED_DISCOUNT_ORDER: '2dc8d8ee-c1dc-dead-daed-a208195b0c46',
  VALID_DISCOUNT_ORDER: '2dc8d8ee-c1dc-dead-daed-a208195b0c48',
  BASIC_RAMPING_ORDER: '0a5584e3-cf55-4ffc-82f6-24f12994525b',
  UPGRADE_PLATFORM_ORDER_FORM: '531e7585-1d8f-5e5a-a5c2-061f9a61c6a0',
  PRIVATE_PLAN_ORDER: '6a788779-c868-4901-9416-57690093c176',
  PUBLIC_PLAN_ORDER: 'aa5e6142-45fb-480a-bc7e-2189689dcd51',
  ORDER_WITH_ACCOUNTS_PAYABLE: '17a19613-13ac-4d4f-9ca1-5b90db214ddd',
  ORDER_WITHOUT_ACCOUNTS_PAYABLE: '5c34084c-2055-4ed5-afd7-c77a2dc3a019',
  SELLER_LED_CLOSE_CONFIRMATION: '2b0b6487-ac22-4c78-943c-b6bb79889989',
  GOD_3575: '74b30343-3341-4a27-b7bb-8718cd0d77e7',
  TAXABLE_UPGRADE_ORDER: 'def6a53b-2214-563f-be27-99dfaae9e7a9',
  TAXABLE_DISCOUNT_ORDER: '5C97B039-F75F-446B-B887-FA18BD90ED52',
  // testing orders across editOrder and editOrderUpgrade
  BASIC_ORDER: '6daa6065-4fce-4455-847c-e061bab615f4',
  BASIC_UPGRADE: '88be22f3-1f18-51f9-bf9e-8d5f648c6224',
  BASIC_RECAST: '7cdadedf-614f-58a3-8e95-cfddf9302ace',
  BASIC_RENEWAL: 'd0f2cd79-387f-58a8-9525-144ee286edc9',
  TEST_RECAST_TERMINATION_DATE: '76ee874d-93f2-4b3b-8f3c-8a6ee80d34ab',
  TEST_MANUAL_CLOSE_LOSS: '81896cf7-2819-49e1-b5be-fd1a4b729fca',
  SELLER_CAN_RECAST_DIFFERENT_PRODUCT: '9871be61-aa89-5e7f-a8ec-7513a5ae04a3',
  SELLER_CAN_NOT_DOWNGRADE_RECAST: '66d750bc-937c-55ec-a715-76e4bfb31b65',
  GOD_3246: '71a8336b-03d3-4e7e-8d66-c958f92802d6',
  GOD_3255: '3fa5d7d0-0618-5f16-9ffc-c83b76783335',
  GOD_3248_INITIAL: 'a48b4c6f-1c5d-4812-a4ef-31b3ae44b820',
  GOD_3248_UPGRADE: '41407401-5874-5e61-adad-1b20c2395f69',
  GOD_3249_CURRENT_CONTRACT: '41b57a53-4897-53d6-a9f1-61322d5ece8c',
  GOD_2519: '71a8336b-03d3-9999-8888-c958f92802d6',
  CUSTOM_PRICING_UPGRADE_ORDER: 'dee4033a-62c2-52e2-8f2f-314a6d920aed',
  GOD_3741_RECAST_DISCOUNT: 'ff79af99-8e7d-530e-a1df-e9c64fba6599',
  ACH_ONLY_ORDER: '2b0b6487-bd45-4c78-943c-b6bb79889987',
  PREPOPULATED_CUSTOMER_ORDER: '98da4072-9fb2-4fff-8379-e64bb500fcc3',
  GOD_5254: 'c8512de3-9f3c-46dd-a035-2cce966e91e6',

  OPEN_TRIAL: '87c5e954-3f9a-40b1-9e3a-42e15dcf7999',
  CLOSED_TRIAL: '87c5e954-3f9a-40b1-9e3a-42e15dcf7b88',

  UPGRADE_ORDER_ANNUAL_PRICE: 'c1476511-1de5-55f5-875f-4c7ab0e235c4',
  UPGRADE_ORDER_MONTHLY_PRICE: 'c5567ecd-7019-55f6-bbe0-4be3ea2d9437',
  //testing order for buyer checkout redesign
  V2_DISCOUNT_ORDER: '031686ab-9743-5c26-9934-eb785b39957b',
  V2_UPGRADE_ORDER: 'c6c26a8b-35e5-5527-be98-baea575c8e23',
  V2_SALES_DISCOUNT_UPGRADE_ORDER: '6048ac8e-df3c-4fac-a6bb-67de81964f6a',
  V2_TERMS_BUTTONS: '0a630107-73cf-4777-83cb-ab31948f2519',
};

export const staticTestCustomers: Record<string, string> = {
  TEST_AGREEMENT_STATUS_CUSTOMER: '21081bc5-0f2e-4a0f-90c2-dd33dd92ed87',
  SUBSCRIPTION_MANAGEMENT_BUYER: 'a708d0c9-1ced-4ab4-8d2b-eaa5ef6ccd82',
  CURRENT_CONTRACT_BUYER: 'a708d0c9-1ced-4ab4-1a39-eaa5ef6ccd82',
  TERMINATE_ORDER_BUYER: '5585852f-1cca-41fa-aad8-0e02dee1428d',
  GREATER_CONSOLIDATED_GUBBINS_INC: 'c24e5c25-4d8c-4d96-bbe2-bf9be646b61f',
  SUBSCRIPTION_TERMINATE_ON_RENEWAL: '07fd554c-85eb-43c3-bdf2-f751eb652a5e',
  SUBSCRIPTION_TERMINATE_SCHEDULED: '108ae37f-2cea-4a7e-b1bd-ab4cb86ac62e',
  USAGE_OVERAGE_MULTIPLE_PERIODS_BUYER: '5979b771-1239-4ce2-bccc-74b5905133b6',
  USAGE_OVERAGE_UNDER_BUYER: '5979b771-1239-4ce2-bc28-74b5905133b6',
  USAGE_PAY_AS_YOU_GO_BUYER: '5979b771-1239-4ab9-bccc-74b5905133b6',
  CURRENT_SPEND_BUYER: '313ee10f-3ab9-56ba-bdc3-7bd0b5661490',
  TERMINATE_RECAST_BUYER: '61495309-fe27-407a-83fd-03f211f5c01e',
  SUBSCRIPTION_STATEMENTS_BUYER: 'c1fec6ba-f3c0-4a89-81d6-2ec2803d03a2',
  ENTITLEMENTS_AND_SPEND_TABLES: 'b0185fca-f24c-4f0e-b3c3-e570e96b5754',
  MONTHLY_ENTITLEMENTS_BUYER: '109442ad-a3dc-4dc4-b21e-5353ffa7d178',
  RAMPING_ENTITLEMENTS_BUYER: 'a0711278-c624-4bc5-8c20-13426d16e49a',
  TEST_RENEWAL_CONTRACT_LENGTH_BUYER: 'bdfc6d77-4d1c-4859-abfb-fd98fc0a9af0',
  TEST_RENEWAL_SUBSCRIPTION_ONLY_BUYER: '2dcc532b-f0d0-4cf4-9b72-4a0aa4301504',
  TEST_BILLING_AND_PAYMENTS_BUYER: '736f52cb-e972-4f6f-a267-e75b0d5cbf2e',
  TEST_BILLING_AND_PAYMENTS_BUYER_WITH_UNPAID:
    '7131cf28-fe26-4996-aff9-173ec5781203',
  TEST_BILLING_PAYMENTS_CUSTOMER_WITH_MUTED_INVOICE:
    '7131cf28-fe26-4996-aff9-173ec5781203',
  TEST_BILLING_PAYMENTS_CUSTOMER_WITH_RETRYABLE_FAILED_PAYMENT:
    '19e3dafe-afb9-44b4-9b3e-07e482b96d67',
  TEST_BILLING_AND_PAYMENTS_USAGE_ORDER_FUTURE_START_DATE:
    'fb813ea8-7cee-40c3-a2ae-ce2eb3630173',
  TEST_BILLING_AND_PAYMENTS_USAGE_ORDER_PAST_START_DATE:
    '2f4f74ae-2e55-4967-b934-f4633ace4243',
  TEST_RENEWAL_CHANGE_CONTRACT_LENGTH: '74d3682c-f3c7-4c7d-ba5c-081416d841ff',
  TEST_RENEWAL_CHANGE_CONTRACT_LENGTH_BUYER:
    '067cfbfb-9f2b-4911-b649-5ed39e17e3a7',
  GOD_4618_RENEWAL_CUSTOMER: '21be30de-1103-4824-9745-ef271523b143',
  GOD_5036_EXTEND_SUCCESS: '45817c79-6d21-58e7-ae0c-8405032cf758',
  GOD_5036_EXTEND_FAILURE: '67aba182-d20a-544c-b980-ff586e16f815',
  GOD_5036_CONVERT_SUCCESS: '399d9623-cf1b-5ffc-8af8-db651c30a716',
  GOD_5036_CONVERT_FAILURE: '7c7a7a65-0136-5913-b965-98c2384adab5',
  GOD_5036_TERMINATE_SUCCESS: '22ccb237-7a7b-59c1-ad2b-c5a34535513c',
  GOD_5036_TERMINATE_FAILURE: '65110faf-d647-5353-8634-a89f0d3d6d1e',
  USAGE_INCLUDED_PRE_COMMITMENT_BUYER: '5979b771-1239-4ce2-bc28-74b5305125a4',
  V2_SAL_336_UPGRADE_BUYER: '1482f4f4-3c7a-4400-9f8f-038e91fc5664',
};

export const staticTestOrderChains: Record<string, string> = {
  TEST_SUBSCRIPTION_PERIOD_SELECT: '8a0a4a9e-0bfe-4bf6-948f-dcdc94758ed2',
  TEST_SQUASHED_LINE_ITEMS: 'c1fec6ba-f1d3-4a89-81d6-2ec2803d03a2',
  TEST_PAYMENT_COLLECTION_DISABLED_NO_EXISTING_PAYMENTS:
    '91c2d07c-b230-23cd-9e07-c41c2c2a7381',
  TEST_PAYMENT_COLLECTION_DISABLED_EXISTING_PAYMENTS:
    '91c2d07c-b230-23cd-9e07-c41c2c2a7322',
  TEST_PAID_INVOICE: 'c24e5c25-4d8c-4d96-bbe2-bf9be646b61f',
  TEST_USAGE_MULTIPLE_PERIODS_SELECT: '4c229835-03b9-463a-1346-c77a9559d1bc',
  TEST_USAGE_OVERAGE_UNDER_SELECT: '4c229835-03b9-463a-9288-c77a9559d1bc',
  TEST_USAGE_PAY_AS_YOU_GO: '4c229835-0234-463a-1346-c77a9559d1bc',
  TEST_STATEMENTS: 'c1fec6ba-f1d3-4a89-93d6-2ec2803d03a2',
  TEST_BILLING_STATEMENTS: 'c1fec6ba-f1d3-4a89-1943-2ec2803d03a2',
  TEST_ENTITLEMENTS_AND_SPEND: '803cbc68-972f-4d87-99f9-781305c39892',
  TEST_MONTHLY_ENTITLEMENTS: '5d86bda8-ddfb-493d-9dbd-e67904e2bfaf',
  TEST_RAMPING_ENTITLEMENTS: 'ff6406c0-400a-4441-aa92-c68354755cc4',
  TEST_RENEWAL_CONTRACT_LENGTH: 'd2decc2a-33ff-48fb-8338-25d0a16ad18d',
  TEST_CURRENT_CONTRACT: 'c1fec6ba-f1d3-4a89-1943-2ec2803d03a2',
  TEST_RENEWAL_SUBSCRIPTION_ONLY: 'd63796b0-8a82-4685-9d7b-e05867bf1ba6',
  TEST_TAX: '48282a71-bcc5-41af-b4ad-df789a096aff',
  TEST_RENEWAL_CHANGE_CONTRACT_LENGTH: '74d3682c-f3c7-4c7d-ba5c-081416d841ff',

  TEST_AGREEMENT_STATUS_UPGRADE_OPEN: 'c446613d-e1dd-4ed3-b01d-ef9896b79ddb',
  TEST_AGREEMENT_STATUS_UPGRADE_CLOSED: '9c839e44-6c27-534b-bc3c-dbf9e1565603',
  TEST_AGREEMENT_STATUS_RECAST_OPEN: 'bfe19011-b2ff-567c-a77e-db229d3c159c',
  TEST_AGREEMENT_STATUS_RECAST_CLOSED: 'f6075531-baaa-55a2-82bc-989fc261c769',
  TEST_AGREEMENT_STATUS_RENEW_OPEN: 'b826e7fe-cf29-5ebd-957c-06c9517327fb',
  TEST_AGREEMENT_STATUS_RENEW_CLOSED: '491d135a-7c3d-5b5a-94e1-7963e305f737',
  TEST_AGREEMENT_STATUS_TERMINATED: '9144c99e-baf2-468f-b7a1-e54dda7a09ac',
  TEST_AGREEMENT_STATUS_TERMINATING: '0a18e74a-fe69-4370-bb4b-38802f44cdc3',
  TEST_AGREEMENT_STATUS_INACTIVE: 'aaacec72-e6ae-42dc-a0e6-2beb80479131',
};

export const StaticTestPlanIds: Record<string, string> = {
  BASIC_TEST_PLAN: 'a09889a5-473d-4dba-9c69-83948aa9ae90',
  ADVANCED_TEST_PLAN: 'f55670e1-1966-4a57-8e31-b8b7f4de8e5c',
};

export const StaticTestInvoices: Record<string, string> = {
  SUBSCRIPTION_BILLING_INVOICE: '11c5c587-42c9-57f9-b265-867cd646f219',
  CLOSED_PAYWALL_CC_SUCCESS_ORDER_INVOICE:
    'c6165192-2040-5069-b10c-77bff4d8c0de',
  CLOSED_PAYWALL_CC_FAILURE_ORDER_INVOICE:
    'cffd76f6-3073-5b50-a1c9-b91b4172060a',
  CLOSED_PAYWALL_ACH_SUCCESS_ORDER_INVOICE:
    '250ad361-26f9-543b-92f2-e47722196518',
  CLOSED_PAYWALL_ACH_FAILURE_ORDER_INVOICE:
    'a7029679-92da-5dba-96cb-386ec0aa3203',
  STANDARD_PAID_BILLING_STATEMENT: 'ad7188cd-ec62-5cf2-944e-f3fb51e4cbcf',
  STANDARD_PAID_BILLING_STATEMENT_WITH_INVOICE_ADJUSTMENT:
    '02ba4138-d50d-5388-abc9-435ba2f4208d',
  BILLING_STATEMENT_INVOICE_ADJUSTMENT: 'ab506f48-5297-5e27-9c33-065705744384',
  BILLING_STATEMENT_INVOICE_TO_BE_FULLY_ADJUSTED:
    '1fc4970d-7ec7-5e2a-9c1d-34d8ae7429d2',
  BILLING_STATEMENT_FAILED_RETRYABLE_PAYMENT:
    'af2611a8-1fce-5db0-9e61-db48ffc8832f',
  TEST_MUTED_INVOICE: '6c8b633f-01eb-5c20-a1ac-2ea4199443f0',
};

export const BuyerSubscriptionManagementTokens: Record<string, string> = {
  VALID_TOKEN: 'e2d3c65c-91d5-4465-b900-80f51b237d23',
  VALID_TOKEN_WITH_PAYMENT_METHOD: 'd090c64b-cae2-4231-a1c9-d665c9e2d70b',
  VALID_TOKEN_WITHOUT_PAYMENT_METHOD: 'f4ec7976-abbb-404f-9a49-8e8892076bf3',
  VALID_TOKEN_WITH_UNPAID_INVOICE: 'c55d627c-c42c-46d5-9ffd-6f15873a3500',
};

export const dts = {
  desktop: 'desktop',
  mobile: 'mobile',
  modal: {
    container: 'modal-container',
    body: 'modal-body',
    title: 'modal-title',
    header: 'modal-header',
    close: 'close-modal',
    cancel: 'modal-cancel',
    ok: 'modal-ok',
  },

  toast: {
    message: 'toast-message',
  },

  spinner: 'spinner',

  components: {
    currencyTierInput: {
      pricingTierInput: {
        oneTime: 'oneTime-fee',
        monthly: 'monthly-fee',
        annual: 'annual-fee',
        usage: 'usage-fee',
        preCommitment: 'pre-commitment-fee',
        percentage: 'percentage',
        percentagePriceMin: 'percentagePriceMin',
      },
    },
    betaLabel: 'components-betaLabel',
    banner: 'components-banner',
    breadcrumb: {
      container: 'components-breadcrumb-container',
      item: 'components-breadcrumb-item',
    },
    addressDisplay: {
      line1: 'components-addressDisplay-line1',
      line2: 'components-addressDisplay-line2',
      line3: 'components-addressDisplay-line3',
    },
    addressInput: {
      container: 'components-addressInput-container',
      line1: 'components-addressInput-line1',
      line2: 'components-addressInput-line2',
      city: 'components-addressInput-city',
      region: 'components-addressInput-region',
      zip: 'components-addressInput-zip',
      country: 'components-addressInput-country',
    },

    cardSelect: 'components-card-select',

    personInput: {
      role: 'components-personInput-role',
      firstName: 'components-personInput-firstName',
      lastName: 'components-personInput-lastName',
      email: 'components-personInput-email',
      preferredName: 'components-personInput-preferredName',
    },
    searchFilter: 'admin-page-search-filter',

    header: {
      login: 'components-header-login',
    },

    footer: {
      demo: 'components-footer-demo',
    },
    tag: 'components-tag',

    loginPanel: {
      container: 'components-loginPanel-container',
      email: 'components-loginPanel-email',
      password: 'components-loginPanel-password',
      error: 'components-loginPanel-error',
      forgot: 'components-loginPanel-forgot',
      submit: 'components-loginPanel-submit',
    },

    forgotPassword: {
      container: 'components-forgotPassword-container',
      email: 'components-forgotPassword-email',
      resetButton: 'components-forgotPassword-resetButton',
      emailSentPanel: 'components-forgotPassword-emailSentPanel',
      resendLink: 'components-forgotPassword-resendLink',
      contactSupport: 'components-forgotPassword-contactSupport',
    },

    passwordResetPanel: {
      title: 'components-passwordResetPanel-title',
      email: 'components-passwordResetPanel-email',
      password: 'components-passwordResetPanel-password',
      newPassword: 'components-passwordResetPanel-newPassword',
      confirmPassword: 'components-passwordResetPanel-confirmPassword',
      submit: 'components-passwordResetPanel-submit',
    },
    paymentTerms: 'components-paymentTerms',
    closeOrder: {
      reason: 'components-closeOrder-reason',
      closeButton: 'components-closeOrder-closeButton',
      deleteButton: 'components-closeOrder-deleteButton',
      contractUpload: 'components-closeOrder-contractUpload',
      purchaseOrderUpload: 'components-closeOrder-purchaseOrderUpload',
      purchaseOrderRequired: 'components-closeOrder-purchaseOrderRequired',
    },
    orderDetails: {
      editOrderButton: 'components-orderDetails-editOrderButton',
      emptyOrder: {
        createOrder: 'componets-orderDetails-emptyOrder-createOrder',
        emptyState: 'components-orderDetails-emptyOrder-emptyState',
      },
      shareOrderIcon: 'components-orderDetails-shareOrderIcon',
      shareLinkModal: {
        shareWithEmailsInput:
          'components-orderDetails-shareLinkModal-shareWithEmailsInput',
        shareLinkInput: 'components-orderDetails-shareLinkModal-shareLinkInput',
      },
    },
    billingScheduleModal: {
      paymentTerms: 'components-billing-schedule-modal-payment-term',
      billingSchedule: 'components-billing-schedule-modal-billing-schedule',
      contractValue: 'components-billing-schedule-modal-contract-value',
      contractTerm: 'components-billing-schedule-modal-contract-term',
      timeline: {
        date: 'components-billing-schedule-modal-timeline-date',
        year: 'components-billing-schedule-modal-timeline-year',
        amount: 'components-billing-schedule-modal-timeline-amount',
      },
    },
    orderFilters: {
      ALL: 'components-orderFilters-ALL',
      BUILDING: 'components-orderFilters-BUILDING',
      SELLER_SIGNING: 'components-orderFilters-SELLER_SIGNING',
      CLOSED: 'components-orderFilters-CLOSED',
      MIGRATING: 'components-orderFilters-MIGRATING',
    },

    orderThankYou: {
      header: 'components-orderThankYou-header',
      redirect: 'components-orderThankYou-redirect',
    },

    buildOrder: {
      title: 'components-buildOrder-title',
      customerName: 'buildOrder-customer-name',
      orderDescription: 'order-description',
      startDate: 'components-buildOrder-startDate',
      selectProduct: 'select-product',
      editPriceButton: 'components-buildOrder-editPriceButton',
      setPriceButton: 'components-buildOrder-setPriceButton',
      alreadyIncluded: 'components-buildOrder-alreadyIncluded',
      commitment: {
        billingSchedule: 'components-buildOrder-commitment-billingSchedule',
        contractLength: 'components-buildOrder-commitment-contractLength',
      },
      discountButton: 'components-buildOrder-discountButton',
      storedPaymentMethodSelect: {
        title: 'components-buildOrder-storedPaymentMethodSelect-title',
        useDefaultMethodButton:
          'components-buildOrder-storedPaymentMethodSelect-useDefult',
        doNotUseDefaultMethodButton:
          'components-buildOrder-storedPaymentMethodSelect-doNotUseDefaultMethodButton',
      },
      comparePlans: 'components-buildOrder-comparePlans',
      creditCardInformationTitle:
        'components-buildOrder-creditCardInformationTitle',
      creditCardForm: 'components-buildOrder-creditCardForm',
      creditCardFormError: 'components-buildOrder-creditCardFormError',
      creditCardFailure: {
        wrapper: 'components-buildOrder-creditCardFailure-wrapper',
        pay: 'components-buildOrder-creditCardFailure-pay',
      },
      currentContract: {
        currentSpend: 'components-buildOrder-currentContract-currentSpend',
        renewalExpectation:
          'components-buildOrder-currentContract-renewalExpectation',
      },
      previewOrderForm: 'compoents-buildOrder-previewOrderForm',
      crmAccountId: 'crm-account-id',
      buyWith: 'components-buildOrder-buyWith',
      accountsPayabale: 'components-buildOrder-accountsPayable',
      createOrderButton: 'components-buildOrder-createOrderButton',
      updateOrderButton: 'components-buildOrder-updateOrderButton',
      saveUpgradeButton: 'components-buildOrder-saveUpgradeButton',
      deleteOrderButton: 'components-buildOrder-deleteOrder',
      contractLengthSelect: 'components-buildOrder-contractLengthSelect',
      upgradeOrderContractLength:
        'components-buildOrder-upgrade-contractLength',
      customContractLengthInput:
        'components-buildOrder-customContractLengthInput',
      scheduleSelector: 'components-buildOrder-scheduleSelector',
      currencySelect: 'components-buildOrder-currencySelect',
      prePopulateCustomer: {
        checkbox: 'components-buildOrder-prePopulateCustomer-checkbox',
        form: 'components-buildOrder-prePopulateCustomer-form',
        warningBanner:
          'components-buildOrder-prePopulateCustomer-warningBanner',
      },

      costTable: {
        costMobile: 'components-buildOrder-costTable-costMobile',
        wrapper: 'components-buildOrder-costTable-wrapper',
        planName: 'components-buildOrder-costTable-planName',
        discountExpires: 'components-buildOrder-costTable-discountExpires',
        subtotal: 'components-buildOrder-costTable-subtotal',
        title: 'components-buildOrder-costTable-title',
        billingScheduleButton:
          'components-buildOrder-costTable-billing-schedule-button',
        priceMismatchTooltip:
          'components-buildOrder-costTable-price-mismatch-tooltip',
        summary: {
          wrapper: 'components-buildOrder-costTable-summary-wrapper',
          subtotal: 'components-buildOrder-costTable-summary-subtotal',
          planCredit: 'components-buildOrder-costTable-summary-planCredit',
          salesDiscount:
            'components-buildOrder-costTable-summary-salesDiscount',
          oneTimeDiscount:
            'components-buildOrder-costTable-summary-oneTimeDiscount',
          totalDiscount:
            'components-buildOrder-costTable-summary-totalDiscount',
          tax: 'components-buildOrder-costTable-summary-tax',
          total: 'components-buildOrder-costTable-summary-total',
        },
        lineItem: {
          wrapper: 'components-buildOrder-costTable-lineItem-wrapper',
          usageUnit: 'components-buildOrder-costTable-lineItem-usageUnit',
          milestoneUnit:
            'components-buildOrder-costTable-lineItem-milestoneUnit',
          discountExpand:
            'components-buildOrder-costTable-lineItem-discountExpand',
          name: 'components-buildOrder-costTable-lineItem-name',
          proRation: 'components-buildOrder-costTable-lineItem-proRation',
          ramping: 'components-buildOrder-costTable-lineItem-ramping',
          quantity: 'components-buildOrder-costTable-lineItem-quantity',
          unitCost: 'components-buildOrder-costTable-lineItem-unitCost',
          subtotal: 'components-buildOrder-costTable-lineItem-subtotal',
          salesDiscount:
            'components-buildOrder-costTable-lineItem-salesDiscount',
          oneTimeDiscount:
            'components-buildOrder-costTable-lineItem-oneTimeDiscount',
          total: 'components-buildOrder-costTable-lineItem-total',
          skuFee: 'components-buildOrder-costTable-lineItem-skuFee',
          badge: 'components-buildOrder-costTable-lineItem-badge',
        },
      },
      simpleCostTable: {
        firstPayment: 'components-buildOrder-simpleCostTable-firstPayment',
        wrapper: 'components-buildOrder-simpleCostTable-wrapper',
        subTotal: 'components-buildOrder-simpleCostTable-subTotal',
        tax: 'components-buildOrder-simpleCostTable-tax',
        planCredit: 'components-buildOrder-simpleCostTable-planCredit',
        dueOn: 'components-buildOrder-simpleCostTable-dueOn',
        lineItem: {
          wrapper: 'components-buildOrder-simpleCostTable-lineItem-wrapper',
          name: 'components-buildOrder-simpleCostTable-lineItem-name',
          proRation: 'components-buildOrder-simpleCostTable-lineItem-proRation',
          quantity: 'components-buildOrder-simpleCostTable-lineItem-quantity',
          milestoneUnit:
            'components-buildOrder-simpleCostTable-lineItem-milestoneUnit',
          usageUnit: 'components-buildOrder-simpleCostTable-lineItem-usageUnit',
          total: 'components-buildOrder-simpleCostTable-lineItem-total',
        },
        share: 'components-buildOrder-simpleCostTable-share',
        statementOfWork:
          'components-buildOrder-simpleCostTable-statementOfWork',
        billingSchedule:
          'components-buildOrder-simpleCostTable-billingSchedule',
        msa: 'components-buildOrder-simpleCostTable-msa',
        commercialTerms:
          'components-buildOrder-simpleCostTable-commercialTerms',
      },

      pricingBreakdown: {
        button: 'components-buildOrder-pricingBreakdown-button',
        wrapper: 'components-buildOrder-pricingBreakdown-wrapper',
        grandTotal: 'components-buildOrder-pricingBreakdown-grandTotal',
        lineItem: {
          name: 'components-buildOrder-pricingBreakdown-lineItem-name',
          quantity: 'components-buildOrder-pricingBreakdown-lineItem-quantity',
          total: 'components-buildOrder-pricingBreakdown-lineItem-total',
          unitPrice:
            'components-buildOrder-pricingBreakdown-lineItem-unitPrice',
          description:
            'components-buildOrder-pricingBreakdown-lineItem-description',
          rate: 'components-buildOrder-pricingBreakdown-lineItem-rate',
          wrapper: 'components-buildOrder-pricingBreakdown-lineItem-wrapper',
          period: 'components-buildOrder-pricingBreakdown-lineItem-period',
          discountTag:
            'components-buildOrder-pricingBreakdown-lineItem-discountTag',
          expandRow: {
            wrapper:
              'components-buildOrder-pricingBreakdown-lineItem-expandRow-wrapper',
            name: 'components-buildOrder-pricingBreakdown-lineItem-expandRow-name',
            unitPrice:
              'components-buildOrder-pricingBreakdown-lineItem-expandRow-unitPrice',
            quantity:
              'components-buildOrder-pricingBreakdown-lineItem-expandRow-quantity',
            rate: 'components-buildOrder-pricingBreakdown-lineItem-expandRow-rate',
            period:
              'components-buildOrder-pricingBreakdown-lineItem-expandRow-period',
            total:
              'components-buildOrder-pricingBreakdown-lineItem-expandRow-total',
          },
        },
        recurringAndNonrecurringTotal:
          'components-buildOrder-pricingBreakdown-recurringAndNonrecurringTotal',
        usageTotal:
          'components-buildOrder-pricingBreakdown-recurringAndNonrecurringTotal',
      },

      offering: {
        list: 'components-buildOrder-offering-list',
        wrapper: 'components-buildOrder-offering-wrapper',
        name: 'components-buildOrder-offering-name',
        price: 'components-buildOrder-offering-price',
        editPricing: {
          cancel: 'components-buildOrder-offering-editPricing-cancel',
          confirm: 'components-buildOrder-offering-editPricing-confirm',
          planPrice: 'components-buildOrder-editPlan-planPrice',
        },
        customPricingLabel:
          'components-buildOrder-offering-customPricing-label',
      },
      addOn: {
        ramping: {
          checkbox: 'components-buildOrder-addon-ramping-checkbox',
          ramps: {
            wrapper: 'components-buildOrder-addon-ramping-ramps-wrapper',
            date: 'components-buildOrder-addon-ramping-ramps-date',
            quantity: 'components-buildOrder-addon-ramping-ramps-quantity',
            remove: 'components-buildOrder-addon-ramping-ramps-remove',
          },
          addRamp: 'components-buildOrder-addon-ramping-addRamp',
          clearAll: 'components-buildOrder-addon-ramping-clearAll',
        },
        addon: 'components-buildOrder-addon-addon',
        included: 'components-buildOrder-addon-included',
        name: 'components-buildOrder-addOn-name',
        summary: 'components-buildOrder-addOn-summary',
        price: 'components-buildOrder-addOn-price',
        choose: 'components-buildOrder-addOn-choose',
        quantity: 'components-buildOrder-addOn-quantity',
        blockSelect: 'components-buildOrder-addOn-blockSelect',
        preCommitment: {
          checkbox: 'components-buildOrder-addOn-precommitment-checkbox',
          quantity: 'components-buildOrder-addOn-precommitment-quantity',
          launchModal: 'components-buildOrder-addOn-precommitment-launchModal',
          modal: {
            overageMethod:
              'components-buildOrder-addOn-precommitment-modal-overageMethod',
            overageValue:
              'components-buildOrder-addOn-precommitment-modal-overageValue',
          },
        },
        pricingStructure: 'components-buildOrder-addOn-pricingStructure',
      },
      companyDetails: {
        companyName: 'primaryUserCompany',
        isBuyerPrimary: 'components-buildOrder-companyDetails-isBuyerPrimary',
        back: 'components-buildOrder-companyDetails-back',
      },
      continue: 'components-buildOrder-continue',
      dealStructure: 'components-buildOrder-dealStructure',
      duration: {
        contractLength: 'components-buildOrder-duration-contractLength',
      },
      orderFormPurchase: {
        setSignatory: 'components-buildOrder-orderFormPurchase-setSignatory',
        primaryWillSign:
          'components-buildOrder-orderFormPurchase-primaryWillSign',
        back: 'components-buildOrder-orderFormPurchase-back',
        complete: 'components-buildOrder-orderFormPurchase-complete',
        setPurchaseOrder:
          'components-buildOrder-orderFormPurchase-setPurchaseOrder',
        referenceNumber:
          'components-buildOrder-orderFormPurchase-referenceNumber',
      },
      pay: {
        billingSchedule: 'components-buildOrder-pay-billingSchedule',
      },
      commercialTerms: {
        redlineModalButton: {
          import:
            'components-buildOrder-commercialTerms-redlineModalButton-import',
          export:
            'components-buildOrder-commercialTerms-redlineModalButton-export',
        },
      },
      renewalTerms: {
        mutual: 'components-buildOrder-renewalTerms-mutual',
        automatic: 'components-buildOrder-renewalTerms-automatic',
      },
      termsAndConditions: {
        accept: 'components-buildOrder-termsAndConditions-accept',
        selector: 'components-buildOrder-termsAndConditions-selector',
        commercialTermsSelect: 'components-buildOrder-commercialTermsSelect',
        modalLink: 'components-buildOrder-termsAndConditions-modalLink',
        showTermsCheck:
          'components-buildOrder-termsAndConditions-showTermsCheck',
        showMsaCheck: 'components-buildOrder-termsAndConditions-showMsaCheck',
        showCommercialTermsCheck:
          'components-buildOrder-termsAndConditions-showCommercialTermsCheck',
        termsModal: 'components-buildOrder-termsAndConditions-termsModal',
        msaModal: 'components-buildOrder-termsAndConditions-msaModal',
        statementOfWorkModal:
          'components-buildOrder-termsAndConditions-statementOfWorkModal',
        termsModalButton:
          'components-buildOrder-termsAndConditions-termsModalButton',
        redlineModalButton: {
          import:
            'components-buildOrder-termsAndConditions-redlineModalButton-import',
          export:
            'components-buildOrder-termsAndConditions-redlineModalButton-export',
        },
        modal: {
          subheader: 'components-buildOrder-termsAndConditions-modal-subheader',
          iframe: 'components-buildOrder-termsAndConditions-modal-iframe',
        },
      },
      pricingStructureModal: {
        pricingText: 'components-buildOrder-pricingStructure',
      },
    },

    fileUpload: 'document-file-upload',

    numericIncrementInput: {
      wrapper: 'numeric-increment',
      decrease: 'components-numericIncrementdecrease-decrease',
      input: 'components-numericIncrementInput-input',
      errorMessage: 'components-numericIncrementInput-errorMessage',
      increase: 'components-numericIncrementincrease-increase',
    },

    sortableTable: {
      table: 'components-sortableTable-table',
      row: 'sortabletable-row',
      column: 'sortabletable-column',
    },

    pagination: {
      nextPage: 'components-pagination-nextPage',
      previousPage: 'components-pagination-previousPage',
      firstPage: 'components-pagination-firstPage',
      lastPage: 'components-pagination-lastPage',
      showRowsSelect: 'components-pagination-showRowsSelect',
    },

    searchDropdown: {
      results: 'components-searchDropdown-results',
      result: 'components-searchDropdown-result',
      input: 'components-searchDropdown-input',
      searchButton: 'components-searchDropdown-searchButton',
    },

    radioSelect: 'radio-select',

    adminPage: {
      customers: {
        tabs: {
          revenueDiagram: 'revenue-diagram-tab',
        },
        customerTable: 'components-adminPage-customers-customer-table',
        currentSpend:
          'components-adminPage-customers-customer-table-current-spend',
        status: 'components-adminPage-customers-customer-table-status',
        renewalExpectation:
          'components-adminPage-customers-customer-table-renewalExpectation',
        customer: {
          milestoneTable: 'components-adminPage-customers-milestone-table',
          milestoneModal: {
            price: 'components-adminPage-customers-milestone-modal-price',
            toComplete:
              'components-adminPage-customers-milestone-modal-toComplete',
            remaining:
              'components-adminPage-customers-milestone-modal-remaining',
            toBeBilled:
              'components-adminPage-customers-milestone-modal-toBeBilled',
          },
          details: {
            spendSelector:
              'components-adminPage-customers-customer-details-spendSelector',
            currentSpend:
              'components-adminPage-customers-customer-details-current-spend',
            table: 'components-adminPage-customers-customer-details-table',
            row: {
              unitPrice:
                'components-adminPage-customers-customer-details-row-unitPrice',
              totalCost:
                'components-adminPage-customers-customer-details-row-totalCost',
              quantity:
                'components-adminPage-customers-customer-details-row-quantity',
              periods:
                'components-adminPage-customers-customer-details-row-periods',
              rate: 'components-adminPage-customers-customer-details-row-rate',
              preCommitment: {
                overages: {
                  totalCost:
                    'components-adminPage-customers-customer-details-row-preCommitment-overages-totalCost',
                },
              },
            },
            entitlements:
              'components-adminPage-customers-customer-details-entitlements',
          },
          subscription: {
            periodSelect:
              'components-adminPage-customers-customer-subscription-periodSelect',
            spendTableLineItem:
              'components-adminPage-customers-customer-subscription-spendTableLineItem',
            entitlementsLineItem:
              'components-adminPage-customers-customer-subscription-entitlementsLineItem',
            editButton:
              'components-adminPage-customers-customer-subscription-details-edit-button',
            pointOfContact:
              'components-adminPage-customers-customer-subscription-details-pointOfContact',
            apContact:
              'components-adminPage-customers-customer-subscription-details-apContact',
            editDetailsModal: {
              owner:
                'components-adminPage-customers-customer-subscription-details-edit-modal-owner',
              primaryUserFirstName:
                'components-adminPage-customers-customer-subscription-details-edit-modal-primaryUserFirstName',
              primaryUserFirstNameError:
                'components-adminPage-customers-customer-subscription-details-edit-modal-primaryUserFirstName-error',
              primaryUserLastName:
                'components-adminPage-customers-customer-subscription-details-edit-modal-primaryUserLastName',
              primaryUserLastNameError:
                'components-adminPage-customers-customer-subscription-details-edit-modal-primaryUserLastName-error',
              primaryUserEmail:
                'components-adminPage-customers-customer-subscription-details-edit-modal-primaryUserEmail',
              primaryUserEmailError:
                'components-adminPage-customers-customer-subscription-details-edit-modal-primaryUserEmail-error',
              accountsPayableEmails:
                'components-adminPage-customers-customer-subscription-details-edit-modal-accountsPayableEmails',
              accountsPayableEmailsError:
                'components-adminPage-customers-customer-subscription-details-edit-modal-accountsPayableEmails-error',
              error:
                'components-adminPage-customers-customer-subscription-details-edit-modal-error',
            },
            lineItem: {
              entitlements: {
                product:
                  'components-adminPage-customers-customer-subscription-lineItem-entitlements-product',
                quantity:
                  'components-adminPage-customers-customer-subscription-lineItem-entitlements-quantity',
              },
            },
            payments: {
              invoiceModal: {
                stripePaymentIntentIdLink:
                  'components-adminPage-customers-customer-payments-invoiceModal-stripePaymentIntentIdLink',
                stripePaymentIntentIdLinkButton:
                  'components-adminPage-customers-customer-payments-invoiceModal-stripePaymentIntentIdLinkButton',
                taxPercent:
                  'components-adminPage-customers-customer-payments-invoiceModal-taxPercent',
                purchaseOrder:
                  'components-adminPage-customers-customer-payments-invoiceModal-purchaseOrder',
                purchaseOrderHref:
                  'components-adminPage-customers-customer-payments-invoiceModal-purchaseOrderHref',
              },
            },
          },
          title: 'components-adminPage-customers-customer-title',
          billingAndPayments: {
            billingStatement: {
              sectionTitle:
                'components-adminPage-customers-customer-billingAndPayments-billingStatement-sectionTitle',
              actions: {
                viewInvoice:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-actions-viewInvoice',
                issueRefund:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-actions-issueRefund',
                addInvoiceAdjustment:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-actions-addInvoiceAdjustment',
                sendInvoice:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-actions-sendInvoice',
                recordPayment:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-actions-recordPayment',
              },
              invoiceAdjustmentModal: {
                modal:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-actions-addInvoiceAdjustment-modal',
                title:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-actions-addInvoiceAdjustment-modalTitle',
                currentBalanceDue:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-actions-addInvoiceAdjustment-currentBalanceDue',
                invoiceAdjustment:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-actions-addInvoiceAdjustment-invoiceAdjustment',
                newBalanceDue:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-actions-addInvoiceAdjustment-newBalanceDue',
                submit:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-actions-addInvoiceAdjustment-submit',
              },
              summarySection: {
                section:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-summarySection',
                billingDate:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-billingDate',
                invoiceStatus:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-summarySection-invoiceStatus',
                paymentStatus:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-summarySection-paymentStatus',
                statementId:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-summarySection-statementId',
                grandTotal:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-summarySection-grandTotal',
                recurringNonRecurringTotal:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-summarySection-recurringNonRecurringTotal',
                recurringTotal:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-summarySection-recurringTotal',
                nonRecurringTotal:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-summarySection-nonRecurringTotal',
                usageTotal:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-summarySection-usageTotal',
                payAsYouGoTotal:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-summarySection-payAsYouGoTotal',
                preCommittedTotal:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-summarySection-preCommittedTotal',
                overagesTotal:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-summarySection-overagesTotal',
              },
              recurringSection: {
                section:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recurringSection',
                lineItem:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recurringSection-lineItem',
                planName:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recurringSection-planName',
                planType:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recurringSection-planType',
                unitPrice:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recurringSection-unitPrice',
                quantity:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recurringSection-quantity',
                period:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recurringSection-period',
                totalCost:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recurringSection-totalCost',
                emptyState:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recurringSection-emptyState',
                paragraphText:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recurringSection-paragraphText',
              },
              usageSection: {
                period:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-period',
                section:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection',
                lineItem:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-lineItem',
                usageLabel:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-usageLabel',
                usageRate:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-usageRate',
                usageVolume:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-usageVolume',
                remainingBalance:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-remainingBalance',
                usedThisPeriod:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-usedThisPeriod',
                totalCost:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-totalCost',
                includedUnits:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-includedUnits',
                preCommitmentRate:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-preCommitmentRate',
                preCommitmentQuantity:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-preCommitmentQuantity',
                preCommitmentCost:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-preCommitmentCost',
                previousBalance:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-previousBalance',
                overageQuantity:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-overageQuantity',
                overagePrice:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-overagePrice',
                emptyState:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-usageSection-emptyState',
              },
              totalsSection: {
                section:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-totalsSection',
                subtotal:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-totalsSection-subtotal',
                subtotalLabel:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-totalsSection-subtotalLabel',
                tax: 'components-adminPage-customers-customer-billingAndPayments-billingStatement-totalsSection-tax',
                taxLabel:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-totalsSection-taxLabel',
                credits:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-totalsSection-credits',
                creditsLabel:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-totalsSection-creditsLabel',
                grandTotal:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-totalsSection-grandTotal',
                recurringTotal:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-totalsSection-recurringTotal',
                usageTotal:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-totalsSection-usageTotal',
                paymentLabel:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-totalsSection-paymentLabel',
                paymentEntry:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-totalsSection-paymentEntry',
              },
              historySection: {
                section:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-historySection',
                emptyState:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-emptyState',
              },
              sendInvoiceModal: {
                emailField:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-sendInvoiceModal-emailField',
                emailBody:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-sendInvoiceModal-emailBody',
                cancelButton:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-sendInvoiceModal-cancelButton',
                sendButton:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-sendInvoiceModal-sendButton',
              },
              recordPaymentModal: {
                notif:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recordPaymentModal-notif',
                remainingAmount:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recordPaymentModal-remainingAmount',
                amountPaid:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recordPaymentModal-amountPaid',
                newBalance:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recordPaymentModal-newBalance',
                cancelButton:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recordPaymentModal-cancel',
                submitButton:
                  'components-adminPage-customers-customer-billingAndPayments-billingStatement-recordPaymentModal-submit',
              },
            },
          },
        },
        revenueDiagram: {
          channelFilter: {
            ALL: 'components-adminPage-customers-revenueDiagram-channelFilter-ALL',
            SALES_LED:
              'components-adminPage-customers-revenueDiagram-channelFilter-SALES_LED',
            SELF_SERVICE:
              'components-adminPage-customers-revenueDiagram-channelFilter-SELF_SERVICE',
          },
          snapshotDate:
            'components-adminPage-customers-revenueDiagram-snapshotDate',
          calendarPicker:
            'components-adminPage-customers-revenueDiagram-calendarPicker',
          today: 'components-adminPage-customers-revenueDiagram-today',
          revenueStage: {
            EVALUATING:
              'components-adminPage-customers-revenueDiagram-revenueStage-EVALUATING',
            FREE: 'components-adminPage-customers-revenueDiagram-revenueStage-FREE',
            PAYING:
              'components-adminPage-customers-revenueDiagram-revenueStage-PAYING',
            CHURNED:
              'components-adminPage-customers-revenueDiagram-revenueStage-CHURNED',
            LAPSED:
              'components-adminPage-customers-revenueDiagram-revenueStage-LAPSED',
          },
        },
      },
      order: {
        details: {
          error: 'components-adminPage-order-details-error',
          customerName: 'order-details-customer-name',
          pointOfContact: 'components-adminPage-order-details-pointOfContact',
          signatoryUser: 'components-adminPage-order-details-signatoryUser',
          orderDescription: 'order-details-description',
          billingSchedule: 'billing-cycle',
          contractTerm: 'contract-term',
          signOrderButton: 'order-details-signOrderButton',
          status: 'components-adminPage-order-details-status',
          manualClose: 'components-adminPage-order-details-manual-close',
          msa: 'components-adminPage-order-details-msa',
          commercialTerms:
            'components-adminPage-order-details-commercial-terms',
          msaText: 'components-adminPage-order-details-msa-text',
          commercialTermsText:
            'components-adminPage-order-details-commercial-terms-text',
          paymentTerms: 'components-adminPage-order-details-payment-terms',
          renewalTerms: 'components-adminPage-order-details-renewal-terms',
          accountsPayable: 'components-adminPage-order-details-accountsPayable',
          productName: 'product-name',
          statementOfWork: 'statement-of-work',
        },
        insights: {
          button: 'components-adminPage-order-insights-button',
          activity: {
            row: 'components-adminPage-order-insights-button-activity-row',
            message:
              'components-adminPage-order-insights-button-activity-message',
          },
        },
        edit: {
          msaText: 'termsEditor',
          commercialTermsText: 'commercialTermsEdit',
          msaTextEmpty: 'components-adminPage-order-edit-msa-text-empty',
          commercialTermsTextEmpty:
            'components-adminPage-order-edit-commercial-terms-text-empty',
          customCommercialTermsButton:
            'components-adminPage-order-edit-commercial-terms-button',
          customMSAButton: 'components-adminPage-order-edit-msa-button',
        },
        newOrderModal: {
          next: 'components-adminPage-order-newOrderModal-next',
          searchDropdownResults:
            'components-adminPage-order-newOrderModal-searchDropdownResults',
          customerSearch:
            'components-adminPage-order-newOrderModal-customerSearch',
          crossIcon: 'components-adminPage-order-newOrderModal-crossIcon',
          renewal: 'components-adminPage-order-newOrderModal-renewal',
          orderUpdateMethods:
            'components-adminPage-order-newOrderModal-orderUpdateMethods',
          selectSubscription:
            'components-adminPage-order-newOrderModal-selectSubscription',
          noSubscription:
            'components-adminPage-order-newOrderModal-noSubscription',
          upgrade: 'components-adminPage-order-newOrderModal-upgrade',
          recast: 'components-adminPage-order-newOrderModal-recast',
        },
      },
      pageNotFound: {
        homeButton: 'components-adminPage-pageNotFound-homeButton',
      },
      bricks: {
        new: 'components-admingPage-bricks-new',
        form: {
          save: 'components-adminPage-bricks-form-save',
          name: 'components-adminPage-bricks-form-name',
          definition: 'components-adminPage-bricks-form-definition',
          schedule: 'components-adminPage-bricks-form-schedule',
          archive: 'components-adminPage-bricks-form-archive',
          archiveModal: {
            archiveButton:
              'components-adminPage-bricks-form-archiveModal-archiveButton',
            error: 'components-adminPage-bricks-form-archiveModal-error',
          },
        },
      },

      header: {
        logo: 'components-adminPage-header-logo',
        orders: 'components-adminPage-header-orders',
        customers: 'components-adminPage-header-customers',
        products: 'components-adminPage-header-products',
        bricks: 'components-adminPage-header-bricks',
        discounts: 'components-adminPage-header-discounts',
        settings: 'components-adminPage-header-settings',
        reports: 'components-adminPage-header-reports',
        userSection: {
          button: 'components-adminPage-header-userSection-button',
          logout: 'components-adminPage-header-userSection-logout',
          beamer: 'components-adminPage-header-userSection-beamer',
          manageYourSubscription:
            'components-adminPage-header-userSection-manageYourSubscription',
        },
      },

      customer: {
        upgradeOrder: 'update-order-button',
        reinstateOrder: 'reinstate-order-button',
        customerStatus: 'customer-status',
        summary: {
          subscriptionStatus: 'summary-status',
          currentSpend: 'summary-current-spend',
          renewalExpectation: 'summary-renewal',
          totalCommittedCurrentSpend: 'summary-total-committed-current-spend',
          dates: 'summary-dates',
        },
        newAgreementModal: {
          upgrade: 'components-adminPage-customer-newAgreementModal-upgrade',
          renewal: 'components-adminPage-customer-newAgreementModal-renewal',
          recast: 'components-adminPage-customer-newAgreementModal-recast',
          terminate:
            'components-adminPage-customer-newAgreementModal-terminate',
          trial: {
            extend:
              'components-adminPage-customer-newAgreementModal-trial-extend',
            convert:
              'components-adminPage-customer-newAgreementModal-trial-convert',
            terminate:
              'components-adminPage-customer-newAgreementModal-trial-terminate',
          },
        },
        statements: {
          statementTable: 'components-adminPage-customers-statement-table',
          billingPeriod:
            'components-adminPage-customers-statement-billing-period',
          recurringTotal:
            'components-adminPage-customers-statement-recurring-total',
          usageTotal: 'components-adminPage-customers-statement-usage-total',
          credits: 'components-adminPage-customers-statement-credits',
          calculatedTaxes: 'components-adminPage-customers-calculated-taxes',
          grandTotal: 'components-adminPage-customers-grand-total',
          modal: {
            invoiceId:
              'components-adminPage-customers-statement-modal-invoice-id',
            subtotal: 'components-adminPage-customers-statement-modal-subtotal',
            grandTotal:
              'components-adminPage-customers-statement-modal-grand-total',
            recurringTotal:
              'components-adminPage-customers-statement-modal-recurring-total',
            usageTotal:
              'components-adminPage-customers-statement-modal-usage-total',
            recurringTable: {
              unitPrice:
                'components-adminPage-customers-statement-modal-recurring-table-unitPrice',
              grandTotal:
                'components-adminPage-customers-statement-modal-recurring-table-grand-total',
            },
            usageTable: {
              grandTotal:
                'components-adminPage-customers-statement-modal-usage-table-grand-total',
            },
          },
        },
        payments: {
          paymentsTable: 'components-adminPage-customers-payment-table',
          paymentsNotEnabled:
            'components-adminPage-customers-payment-not-enabled',
          currentBalance: 'components-adminPage-customers-current-balance',
        },
        extendTrialModal: {
          additionalDays:
            'components-adminPage-customer-extendTrialModal-additionalDays',
          startDate: 'components-adminPage-customer-extendTrialModal-startDate',
          endDate: {
            current:
              'components-adminPage-customer-extendTrialModal-endDate-current',
            new: 'components-adminPage-customer-extendTrialModal-endDate-new',
          },
          confirm: 'components-adminPage-customer-extendTrialModal-confirm',
          cancel: 'components-adminPage-customer-extendTrialModal-cancel',
          error: 'components-adminPage-customer-extendTrialModal-error',
          mutationError:
            'components-adminPage-customer-extendTrialModal-mutationError',
        },
        terminateOrderModal: {
          terminationNotes:
            'components-adminPage-customer-terminateOrderModal-terminationNotes',
          notificationEmails:
            'components-adminPage-customer-terminateOrderModal-notificationEmails',
          terminationDate:
            'components-adminPage-customer-terminateOrderModal-terminationDate',
          confirm: 'components-adminPage-customer-terminateOrderModal-confirm',
          cancel: 'components-adminPage-customer-terminateOrderModal-cancel',
        },
      },

      discounts: {
        new: 'components-adminPage-discounts-new',
        modal: {
          code: 'components-adminPage-discounts-modal-code',
          description: 'components-adminPage-discounts-modal-description',
          expiresAt: 'components-adminPage-discounts-modal-expiresAt',
          minimum: 'components-adminPage-discounts-modal-minimum',
          sales: 'components-adminPage-discounts-modal-sales',
          oneTime: 'components-adminPage-discounts-modal-oneTime',
          limit: {
            total: 'total-discount-summary',
            remaining: 'remaining-discount-summary',
          },
          planCredits: 'components-adminPage-discounts-modal-planCredits',
          planCreditsReason:
            'components-adminPage-discounts-modal-planCreditsReason',
          subtotal: 'components-adminPage-discounts-modal-subtotal',
          minimumSpend: 'components-adminPage-discounts-modal-minimumSpend',
          apply: 'components-adminPage-discounts-modal-apply',
          cancel: 'components-adminPage-discounts-modal-cancel',
        },
        row: {
          wrapper: 'components-adminPage-discounts-row-wrapper',
          expand: 'expand-line-item-card',
          code: 'components-adminPage-discounts-row-code',
          discount: 'components-adminPage-discounts-row-discount',
          edit: 'components-adminPage-edits-row-edit',
          salesDiscount: 'line-item-sales-discount-input',
          salesFixed: 'line-item-sales-fixed-input',
          oneTimeRate: 'line-item-onetime-rate-discount-input',
          oneTimeFixed: 'line-item-onetime-fixed-discount-input',
          subtotal: 'discount-line-item-card-subtotal',
        },
        lineItems: 'discount-line-items',
      },
      reports: {
        dateFilter: {
          dateSelector: 'components-adminPage-reports-dateFilter-dateSelector',
          startDate: 'components-adminPage-reports-dateFilter-startDate',
          endDate: 'components-adminPage-reports-dateFilter-endDate',
        },
        revenueWaterfall: {
          waterfall: 'components-adminPage-reports-revenueWaterfall-waterfall',
          downloadCSV:
            'components-adminPage-reports-revenueWaterfall-downloadCSV',
          table: 'components-adminPage-reports-revenueWaterfall-table',
        },
        renewalReport: {
          report: 'components-adminPage-reports-renewalReport-report',
          downloadCSV: 'components-adminPage-reports-renewalReport-downloadCSV',
        },
        rawDataReport: {
          report: 'components-adminPage-reports-rawDataReport-report',
          downloadCSV: 'components-adminPage-reports-rawDataReport-downloadCSV',
          table: {
            billingAmount:
              'components-adminPage-reports-rawDataReport-table-billingAmount',
          },
        },
        revenueReport: {
          report: 'components-adminPage-reports-revenueReport-report',
          downloadCSV: 'components-adminPage-reports-revenueReport-downloadCSV',
        },
        invoiceReceivablesReport: {
          report:
            'components-adminPage-reports-invoiceReceivablesReport-report',
        },
        customerCountReport: {
          report: 'components-adminPage-reports-customerCountReport-report',
          downloadCSV:
            'components-adminPage-reports-customerCountReport-downloadCSV',
        },
        bookingsReport: {
          report: 'components-adminPage-reports-bookingsReport-report',
          downloadCSV:
            'components-adminPage-reports-bookingsReport-downloadCSV',
        },
        TaxReports: {
          taxRemit: 'components-adminPage-reports-TaxReports-taxRemit',
          downloadCSV: 'components-adminPage-reports-TaxReports-downloadCSV',
          dateSelector: 'components-adminPage-reports-TaxReports-dateSelector',
          startDate: 'components-adminPage-reports-TaxReports-startDate',
          endDate: 'components-adminPage-reports-TaxReports-endDate',
          taxTrancDone: 'components-adminPage-reports-TaxReports-taxTrancDone',
          emptyData: 'components-adminPage-reports-TaxReports-emptyData',
        },
        chartsSection: {
          section: 'components-adminPage-reports-charts',
          dateFilter: 'components-adminPage-reports-charts-dateFilter',
        },
      },

      product: {
        warning: {
          card: 'components-adminPage-product-warning-card',
          idx: 'components-adminPage-product-warning-idx',
          row: 'components-adminPage-product-warning-row',
          warning: 'components-adminPage-product-warning-warning',
        },

        new: 'components-adminPage-product-new',
        create: {
          button: 'components-adminPage-product-create',
          logo: {
            img: 'components-adminPage-product-logo-img',
            input: 'components-adminPage-product-logo-input',
            edit: 'components-adminPage-product-logo-edit',
          },
          name: 'components-adminPage-product-create-name',
          description: 'components-adminPage-product-create-description',
          descriptionShort:
            'components-adminPage-product-create-descriptionShort',
        },
        row: {
          name: 'components-adminPage-product-row-name',
        },
        plans: {
          new: 'components-adminPage-product-plans-new',
          postscript: 'plan-postscript',
          schedule: 'plan-schedule',
          allowedBilling: 'plan-allowed-billing',
          fees: 'plan-fees',
          trial: 'plan-trial',
          privacy: 'plan-privacy',
          name: 'plan-name',
          status: 'plan-status',
          published: 'published',
          definition: 'plan-definition',
          summary: 'plan-summary',
          code: 'plan-code',
          expirationConditions:
            'components-adminPage-product-plans-expirationConditions',
          planVersionId: 'components-adminPage-product-plans-planVersionId',
          autoConvert: 'components-adminPage-product-plans-autoConvert',
          creditCardRequired:
            'components-adminPage-product-plans-creditCardRequired',

          create: {
            save: 'components-adminPage-product-plans-save',
            publish: 'components-adminPage-product-plans-publish',
            button: 'components-adminPage-product-plans-button',

            feePostscript: 'plan-fee-postscript',
            name: 'components-adminPage-product-plans-name',
            definition: 'components-adminPage-product-plans-definition',
            summary: 'components-adminPage-product-plans-summary',
            code: 'components-adminPage-product-plans-code',
            hasSubscriptionFee:
              'components-adminPage-product-plans-hasSubscriptionFee',
            noSubscriptionFee:
              'components-adminPage-product-plans-noSubscriptionFee',
            isTrialPlan:
              'components-adminPage-product-plans-create-isTrialPlan',

            trial: {
              defaultContractLength:
                'components-adminPage-product-plans-create-trial-defaultContractLength',
              expirationEmailNotification:
                'components-adminPage-product-plans-create-trial-expirationEmailNotification',
              creditCardRequired: {
                collect:
                  'components-adminPage-product-plans-create-trial-creditCardRequired-collect',
                doNotCollect:
                  'components-adminPage-product-plans-create-trial-creditCardRequired-doNotCollect',
              },
              autoConvert: {
                true: 'components-adminPage-product-plans-create-trial-autoConvert-true',
                false:
                  'components-adminPage-product-plans-create-trial-autoConvert-false',
              },
            },

            planTemplate: {
              wrapper:
                'components-adminPage-product-plans-create-planTemplate-wrapper',
              standard:
                'components-adminPage-product-plans-create-planTemplate-standard',
              trial:
                'components-adminPage-product-plans-create-planTemplate-trial',
            },

            fees: {
              wrapper: 'components-adminPage-product-plans-fees-wrapper',
            },
            displaySchedule:
              'components-adminPage-product-plans-displaySchedule',

            // old to deprecate
            type: 'components-adminPage-product-plans-type',
            units: 'components-adminPage-product-plans-units',
            schedule: 'components-adminPage-product-plans-schedule',
            pricingStructure:
              'components-adminPage-product-plans-pricingStructure',
            priceAddendum: 'components-adminPage-product-plans-priceAddendum',
            maxUnits: 'components-adminPage-product-plans-maxUnits',

            bricks: 'components-adminPage-product-plans-bricks',
            brickLibrary: {
              wrapper:
                'components-adminPage-product-plans-brickLibrary-wrapper',
              brick: 'components-adminPage-product-plans-brickLibrary-brick',
              create: 'components-adminPage-product-plans-brickLibrary-create',
            },
            selectedBricks: {
              wrapper:
                'components-adminPage-product-plans-selectedBricks-wrapper',
              brick: {
                name: 'components-adminPage-product-plans-selectedBricks-brick-name',
                wrapper:
                  'components-adminPage-product-plans-selectedBricks-brick-wrapper',
                edit: 'components-adminPage-product-plans-selectedBricks-brick-edit',
                warning:
                  'components-adminPage-product-plans-selectedBricks-brick-warning',
                delete:
                  'components-adminPage-product-plans-selectedBricks-brick-delete',
                pricingLabel:
                  'components-adminPage-product-plans-selectedBricks-brick-pricingLabel',
                privateLabel:
                  'components-adminPage-product-plans-selectedBricks-brick-privateLabel',
              },
            },
            editBrickPricing: {
              additionalSettings: 'modals-editBrickPricing-additional-settings',
              pricingStructure: 'modals-editBrickPricing-pricing-structure',
              pricing: 'modals-editBrickPricing-pricing',
              priceType: 'modals-editBrickPricing-priceType',
              percentageBaseBrickIds:
                'modals-editBrickPricing-percentageBaseBrickIds',
              planUnits: 'modals-editBrickPricing-plan-units',
              structure: {
                flatRate: 'modals-editBrickPricing-structure-flatRate',
                tiered: 'modals-editBrickPricing-structure-tiered',
                volume: 'modals-editBrickPricing-structure-volume',
                block: 'modals-editBrickPricing-structure-block',
              },
              flatRate: {
                monthly: 'modals-editBrickPricing-monthly',
                annual: 'modals-editBrickPricing-annual',
              },
              tier: {
                quantity: 'modals-editBrickPricing-tier-quantity',
                blockMaxQuantity:
                  'modals-editBrickPricing-tier-blockMaxQuantity',
              },
              deferPricing: {
                yes: 'modals-deferPricing-yes',
                no: 'modals-deferPricing-no',
              },
              update: 'modals-editBrickPricing-update',
              addAUnit: 'modals-editBrickPricing-addAUnit',
              includeUnits: {
                no: 'modals-editBrickPricing-includeUnits-no',
                yes: 'modals-editBrickPricing-includeUnits-yes',
                quantity: 'modals-editBrickPricing-includeUnits-quantity',
                requiredQuantity:
                  'modals-editBrickPricing-includeUnits-requiredQuantity',
              },
            },
          },
          edit: {
            button: 'components-adminPage-product-plans-edit-button',
            publish: 'components-adminPage-product-plans-edit-publish',
            displayBillingTypeSelect:
              'components-adminPage-product-plans-edit-displayBillingTypeSelect',
          },
        },
        details: {
          edit: {
            button: 'components-adminPage-product-details-edit-button',
          },
        },
        finance: {
          renewalNotifications: 'renewal-notification-settings',
          renewalTerms: 'renewal-terms',
          edit: 'edit-button',
          update: 'update-Finance',
          allowCreditCard: 'allow-credit-card',
          allowCheck: 'allow-check',
          allowAch: 'allow-ach',
          allowOrderForm: 'allow-order-form',
          creditCard: 'credit-card',
          check: 'check',
          ach: 'ach',
          creditCardOption: 'credit-card-option-value',
          checkOption: 'check-option-value',
          achOption: 'ach-option-value',
        },
        nav: {
          details: 'components-adminPage-product-nav-details',
          plans: 'components-adminPage-product-nav-plans',
          legal: 'components-adminPage-product-nav-legal',
          finance: 'components-adminPage-product-nav-finance',
          pricingPage: 'components-adminPage-product-nav-pricingPage',
          communications: 'components-adminPage-product-nav-communications',
        },
        pricingPage: {
          edit: {
            pricingPage:
              'components-adminPage-product-pricingPage-edit-pricingPage',
          },
          landing: {
            preview: 'components-adminPage-product-pricingPage-landing-preview',
          },
          preview: {
            title: 'components-adminPage-product-pricingPage-preview-title',
          },
        },
      },

      settings: {
        nav: {
          company: 'components-adminPage-settings-nav-company',
          team: 'components-adminPage-settings-nav-team',
          branding: 'components-adminPage-settings-nav-branding',
          legal: 'components-adminPage-settings-nav-legal',
          finance: 'components-adminPage-settings-nav-finance',
          integrations: 'components-adminPage-settings-nav-integrations',
          notifications: 'components-adminPage-settings-nav-notifications',
        },

        company: {
          edit: 'components-adminPage-settings-company-edit',
          update: 'components-adminPage-settings-company-update',
        },
        team: {
          toolbar: 'components-adminPage-settings-team-toolbar',
          new: 'components-adminPage-settings-team-new',
          edit: 'components-adminPage-settings-team-edit',
          deactivate: 'components-adminPage-settings-team-deactivate',
          status: 'components-adminPage-settings-team-status',
          modal: {
            firstName: 'components-adminPage-settings-team-modal-firstName',
            lastName: 'components-adminPage-settings-team-modal-lastName',
            email: 'components-adminPage-settings-team-modal-email',
            role: 'components-adminPage-settings-team-modal-role',
            maximumDiscount:
              'components-adminPage-settings-team-modal-maximumDiscount',
            admin: 'components-adminPage-settings-team-modal-admin',
            save: 'components-adminPage-settings-team-modal-save',
          },
          details: {
            maximumDiscount:
              'components-adminPage-settings-team-details-maximumDiscount',
            resetPasswordButton:
              'components-adminPage-settings-team-details-resetPasswordButton',
            resetPasswordModal: {
              title:
                'components-adminPage-settings-team-details-resetPasswordModal-title',
            },
            email: 'components-adminPage-settings-team-details-email',
            role: 'components-adminPage-settings-team-details-role',
          },
        },

        legal: {
          edit: 'components-adminPage-settings-legal-edit',
          update: 'components-adminPage-settings-legal-update',
          archiveButton: 'components-adminPage-settings-legal-archiveButton',
          termsUpload: {
            newTermsConditionsUploadButton:
              'components-adminPage-settings-legal-newTermsConditionsUploadButton',
            newCommercialTermsUploadButton:
              'components-adminPage-settings-legal-newCommercialTermsUploadButton',
            createButton: 'components-adminPage-settings-legal-createButton',
          },
          archiveTermModal: {
            archive:
              'components-adminPage-settings-legal-archiveTermModal-archive',
            title: 'components-adminPage-settings-legal-archiveTermModal-title',
          },
          termsDocumentRows:
            'components-adminPage-onboarding-legal-termsDocumentRows',
          commercialTermsDocumentRows:
            'components-adminPage-onboarding-legal-commercialTermsDocumentRows',
          commercialTerms:
            'components-adminPage-settings-legal-commercialTerms',
          showTerms: 'components-adminPage-settings-legal-showTerms',
          editForm: {
            documentSigner:
              'components-adminPage-settings-legal-editForm-documentSigner',
            lockOrder:
              'components-adminPage-settings-legal-editForm-default-lockOrder-checkbox',
          },
          details: {
            documentSigner:
              'components-adminPage-settings-legal-details-documentSigner',
            lockOrder: 'components-adminPage-settings-legal-details-lockOrder',
          },
        },
        finance: {
          editAcceptedPaymentMethods: {
            option:
              'components-adminPage-settings-finance-editAcceptedPaymentMethods-option',
            minimumOrderFormTcvWarning:
              'components-adminPage-settings-finance-editAcceptedPaymentMethods-minimumOrderFormTcvWarning',
            minimumOrderFormTcv:
              'components-adminPage-settings-finance-editAcceptedPaymentMethods-minimumOrderFormTcv',
            checkoutStripeWarning:
              'components-adminPage-settings-finance-editAcceptedPaymentMethods-checkoutStripeWarning',
            checkoutOptions:
              'components-adminPage-settings-finance-editAcceptedPaymentMethods-checkoutOptions',
            paywallStripeWarning:
              'components-adminPage-settings-finance-editAcceptedPaymentMethods-paywallStripeWarning',
            paywallOptions:
              'components-adminPage-settings-finance-editAcceptedPaymentMethods-paywallOptions',
          },
          currencies: 'components-adminPage-settings-finance-currencies',
          edit: 'components-adminPage-settings-finance-edit',
          customInvoiceTextWrapper:
            'components-adminPage-settings-finance-custom-invoice-wrapper',
          customInternationalInvoiceTextWrapper:
            'components-adminPage-settings-finance-custom-international-invoice-wrapper',
          customInvoiceText:
            'components-adminPage-settings-finance-custom-invoice-text',
          editCheckAddress:
            'components-adminPage-settings-finance-custom-edit-check-address',
          checkAddress: 'components-adminPage-settings-finance-check-address',
          customInternationalInvoiceText:
            'components-adminPage-settings-finance-custom-international-invoice-text',
          customInvoiceTextEditor:
            'components-adminPage-settings-finance-custom-invoice-text-editor',
          supportMultipleCurrencies:
            'components-adminPage-settings-finance-supportMultipleCurrencies',
          checkoutAcceptedMethods:
            'components-adminPage-settings-finance-checkoutAcceptedMethods',

          paywallAcceptedMethods:
            'components-adminPage-settings-finance-paywallAcceptedMethods',

          checkoutMinimumOrderTCV:
            'components-adminPage-settings-finance-checkoutMinimumOrderTCV',

          useHqAddress: 'use-hq-address-checkbox',
          isLockbox: 'is-lockbox-checkbox',
          banksTable: 'components-adminPage-settings-finance-banksTable',
          editInvoiceText: {
            form: 'components-adminPage-settings-finance-editInvoiceText-form',
            editor:
              'components-adminPage-settings-finance-editInvoiceText-editor',
          },
          editBankDetails: {
            container:
              'components-adminPage-settings-finance-editBankDetails-container',
            form: 'components-adminPage-settings-finance-editBankDetails-form',
            header:
              'components-adminPage-settings-finance-editBankDetails-header',
            bankName:
              'components-adminPage-settings-finance-editBankDetails-bankName',
            swiftCode:
              'components-adminPage-settings-finance-editBankDetails-swiftCode',
            routingNumber:
              'components-adminPage-settings-finance-editBankDetails-routingNumber',
            accountNumber:
              'components-adminPage-settings-finance-editBankDetails-accountNumber',
            addBank:
              'components-adminPage-settings-finance-editBankDetails-addBank',
          },
        },
        branding: {
          edit: 'components-adminPage-settings-branding-edit',
          update: 'components-adminPage-settings-branding-update',
          logo: {
            img: 'components-adminPage-settings-branding-logo-img',
            input: 'components-adminPage-settings-branding-logo-input',
            edit: 'components-adminPage-settings-branding-logo-edit',
          },
          darkLogo: {
            img: 'components-adminPage-settings-branding-darkLogo-img',
            input: 'components-adminPage-settings-branding-darkLogo-input',
            edit: 'components-adminPage-settings-branding-darkLogo-edit',
          },
          color: {
            value: 'components-adminPage-settings-branding-color-value',
            input: 'components-adminPage-settings-branding-color-input',
          },
        },
        integrations: {
          crm: {
            wrapper: 'components-adminPage-settings-integrations-crm-wrapper',
            salesforce:
              'components-adminPage-settings-integrations-crm-salesforce',
            hubspot: 'components-adminPage-settings-integrations-crm-hubspot',
            next: 'components-adminPage-settings-integrations-crm-next',
            connectCrmModal:
              'components-adminPage-settings-integrations-crm-connect-crm-modal',
            mapper: {
              salesbricksFieldSelect:
                'components-adminPage-settings-integrations-crm-connect-crm-mapper-salesbricks-field-select',
              salesbricksEnumSelect:
                'components-adminPage-settings-integrations-crm-connect-crm-mapper-salesbricks-enum-select',
              crmFieldSelect:
                'components-adminPage-settings-integrations-crm-connect-crm-mapper-crm-field-select',
              crmEnumSelect:
                'components-adminPage-settings-integrations-crm-connect-crm-mapper-crm-enum-select',
              addField:
                'components-adminPage-settings-integrations-crm-connect-crm-mapper-crm-add-field',
              save: 'components-adminPage-settings-integrations-crm-connect-crm-mapper-crm-save',
            },
          },
          slack: {
            notificationSelectList:
              'components-adminPage-settings-integrations-slack-configurator-list',
            notificationSelectListEntry:
              'components-adminPage-settings-integrations-slack-configurator-list-entry',
          },
        },

        notifications: {
          customerEmails: {
            emailSettingItem:
              'components-adminPage-settings-notifications-customerEmails-emailSettingItem',
            billing: {
              section:
                'components-adminPage-settings-notifications-customerEmails-billingEmails',
              senderEmail:
                'components-adminPage-settings-notifications-customerEmails-billingEmails-senderEmail',
            },
            sales: {
              section:
                'components-adminPage-settings-notifications-customerEmails-salesEmails',
              senderEmail:
                'components-adminPage-settings-notifications-customerEmails-salesEmails-senderEmail',
            },
            mapper: {
              name: 'components-adminPage-settings-notifications-customerEmails-emailNotification-name',
              eventTrigger:
                'components-adminPage-settings-notifications-customerEmails-emailNotification-eventTrigger',
              status:
                'components-adminPage-settings-notifications-customerEmails-emailNotification-status',
            },
          },
        },
      },

      onboarding: {
        wrapper: 'components-adminPage-onboarding-wrapper',
        next: 'components-adminPage-onboarding-next',
        company: {
          description: 'components-adminPage-onboarding-company-description',
          phone: 'components-adminPage-onboarding-company-phone',
          website: 'components-adminPage-onboarding-company-website',
        },
        contact: {
          sales: 'components-adminPage-onboarding-contact-sales',
          support: 'components-adminPage-onboarding-contact-support',
        },
        legal: {
          signer: 'components-adminPage-onboarding-legal-signer',
          termsName: 'components-adminPage-onboarding-legal-termsName',
          termsDocumentUpload:
            'components-adminPage-onboarding-legal-termsDocumentUpload',
          privacy: 'components-adminPage-onboarding-legal-privacy',
          accountReceivable:
            'components-adminPage-onboarding-legal-accountReceivable',
        },
        branding: {
          colorPicker: 'components-adminPage-onboarding-branding-colorPicker',
          logo: {
            input: 'components-adminPage-onboarding-branding-logo-input',
            edit: 'components-adminPage-onboarding-branding-logo-edit',
          },
          logoDark: {
            input: 'components-adminPage-onboarding-branding-logoDark-input',
            edit: 'components-adminPage-onboarding-branding-logoDark-edit',
          },
        },
      },
    },

    pricing: {
      pane: 'pricing-pane',
      wrapper: 'components-adminPage-pricing-wrapper',
      sku: {
        name: 'components-adminPage-pricing-sku-name',
        summary: 'components-adminPage-pricing-sku-summary',
        price: 'components-adminPage-pricing-sku-price',
        new: 'components-adminPage-pricing-sku-new',
      },
    },

    payInvoice: {
      cost: {
        buyer: 'components-payInvoice-cost-buyer',
        poCode: 'components-payInvoice-cost-poCode',
        invoiceNumber: 'components-payInvoice-cost-invoiceNumber',
        date: 'components-payInvoice-cost-date',
        paymentTerms: 'components-payInvoice-cost-paymentTerms',
        amount: 'components-payInvoice-cost-amount',
        dueOn: 'components-payInvoice-cost-dueOn',
      },
      payByTitle: 'components-payInvoice-payByTitle',
      payWithCheck: 'components-payInvoice-payWithCheck',
      payWith: 'components-payInvoice-payWith',
      payButton: 'components-payInvoice-payButton',
      creditCardSelector: 'components-payInvoice-creditCardSelector',
    },

    trialCheckout: {
      title: 'components-trialCheckout-title',
      subtitle: 'components-trialCheckout-subtitle',
      startTrial: 'components-trialCheckout-startTrial',
      success: 'components-trialCheckout-success',
    },

    orderSigning: {
      title: 'components-orderSigning-title',
      complete: 'components-orderSigning-complete',
      buyerSignature: 'components-orderSigning-buyerSignature',
      share: 'components-orderSigning-share',
      docusign: 'components-orderSigning-docusign',
      back: 'components-orderSigning-back',
      sign: 'components-orderSigning-sign',
      revert: 'components-orderreverting-revert',
      addSignature: 'components-orderSigning-addSignature',
      editSignature: 'components-orderSigning-editSignature',

      preview: 'components-orderSigning-preview',

      signature: {
        fullname: 'components-orderSigning-signature-fullname',
      },

      signatureModal: {
        firstName: 'components-orderSigning-signatureModal-firstName',
        lastName: 'components-orderSigning-signatureModal-lastName',
        title: 'components-orderSigning-signatureModal-title',
        error: 'components-orderSigning-signatureModal-error',
      },
    },

    subscription: {
      error: 'components-subscription-error',
      header: 'components-subscription-header',
      upgradeButton: 'components-subscription-upgradeButton',
      tabs: {
        details: 'details-tab',
        billing: 'billing-tab',
        paymentMethods: 'payment-methods-tab',
      },
      details: {
        editButton: 'components-subscription-details-editButton',
        plan: {
          planList: 'components-subscription-details-plan-planList',
          upgradePlanList:
            'components-subscription-details-plan-upgradePlanList',
        },
        productName: 'components-subscription-details-productName',
        billingSchedule: 'components-subscription-details-billingSchedule',
        pointOfContact: 'components-subscription-details-pointOfContact',
        accountsPayable: 'components-subscription-details-accountsPayable',
        subscriptionId: 'components-subscription-details-subscriptionId',
        buyerSubscriptionManagementUrl:
          'components-subscription-details-buyerSubscriptionManagementUrl',
        subscriptionPeriod:
          'components-subscription-details-subscriptionPeriod',
        edit: {
          firstName: 'components-subscription-details-edit-firstName',
          lastName: 'components-subscription-details-edit-lastName',
          email: 'components-subscription-details-edit-email',
          accountsPayableEmail:
            'components-subscription-details-edit-accountsPayableEmail',
          updateButton: 'components-subscription-details-edit-updateButton',
        },
      },
      billing: {
        invoicesTable: 'components-subscription-billing-invoicesTable',
        payNowButton: 'components-subscription-billing-payNowButton',
        cancelButton: 'components-subscription-billing-cancelButton',
        cancelModal: {
          button: 'components-subscription-billing-cancelModal-button',
        },
      },
      paymentMethods: {
        title: 'components-subscription-paymentMethods-title',
        subTitle: 'components-subscription-paymentMethods-subTitle',
        methodsBlockTitle:
          'components-subscription-paymentMethods-methodsBlockTitle',
        newCardButton: 'components-subscription-paymentMethods-newCardButton',
        cardsList: 'components-subscription-paymentMethods-cardsList',
        cardElement: 'components-subscription-paymentMethods-cardElement',
        cardBrand: 'components-subscription-paymentMethods-cardBrand',
        cardLast4: 'components-subscription-paymentMethods-cardLast4',
      },
      termsAndAgreements: {
        masterServiceAgreement: {
          button: 'master-service-agreement-term-item',
        },
        commercialTerms: {
          button: 'commercial-terms-term-item',
        },
        statementOfWork: {
          button: 'statement-of-work-term-item',
        },
      },
      upgrade: {
        header: 'components-subscription-upgrade-header',
      },
    },
    lite: {
      entry: {
        button: 'components-lite-entry-button',
      },
      orders: {
        logoUpload: 'document-file-upload',
        docType: 'components-lite-orders-docType',
        refId: 'components-lite-orders-refId',
        customerCompanyName: 'components-lite-orders-customerCompanyName',
        sellerCompanyName: 'components-lite-orders-sellerCompanyName',
        sellerFirstName: 'components-lite-orders-sellerFirstName',
        sellerLastName: 'components-lite-orders-sellerLastName',
        sellerEmail: 'components-lite-orders-sellerEmail',
        endDate: 'components-lite-orders-endDate',
        addLineItems: 'components-lite-orders-addLineItems',
        lineItemModal: {
          productOrService:
            'components-lite-orders-lineItemModal-productOrService',
          unitPrice: 'components-lite-orders-lineItemModal-unitPrice',
          occurrence: 'components-lite-orders-lineItemModal-occurrence',
          quantity: 'components-lite-orders-lineItemModal-quantity',
          discount: 'components-lite-orders-lineItemModal-discount',
          netPrice: 'components-lite-orders-lineItemModal-netPrice',
          xButton: 'components-lite-orders-lineItemModal-xButton',
          addLineItems: 'components-lite-orders-lineItemModal-addLineItems',
          addToOrderFormButton:
            'components-lite-orders-lineItemModal-addToOrderFormButton',
        },
        url: 'components-lite-orders-url',
        addProductDefinitions: 'components-lite-orders-addProductDefinitions',
        productDefinitions: 'components-lite-orders-productDefinitions',
        addCommercialTerms: 'components-lite-orders-addCommercialTerms',
        commercialTerms: 'components-lite-orders-commercialTerms',
        addStatementOfWork: 'components-lite-orders-addStatementOfWork',
        statementOfWork: 'components-lite-orders-statementOfWork',
        generateButton: 'components-lite-orders-generateButton',
        nextStepsModal: {
          signUpButton: 'components-lite-orders-nextStepsModal-signUpButton',
        },
      },
      signUp: {
        email: 'components-lite-signUp-email',
        password: 'components-lite-signUp-password',
        button: 'components-lite-signUp-button',
      },
      downloadDocxButton: 'components-lite-downloadDocxButton',
      admin: {
        addressLine1: 'components-lite-admin-addressLine1',
        city: 'components-lite-admin-city',
        zip: 'components-lite-admin-zip',
        shareWith: 'components-lite-admin-shareWith',
        shareOrderIcon: 'components-lite-admin-shareOrderIcon',
        shareOrderButton: 'components-lite-admin-shareOrderButton',
        newOrderButton: 'components-lite-admin-newOrderButton',
      },
      header: {
        logo: 'components-lite-header-logo',
        orders: 'components-lite-header-orders',
        userSection: {
          button: 'components-lite-header-userSection-button',
          account: 'components-lite-header-userSection-account',
          logout: 'components-lite-header-userSection-logout',
        },
      },
      account: {
        edit: 'components-lite-account-edit',
        updateButton: 'components-lite-account-updateButton',
      },
    },
    timelineStepper: {
      entry: 'timelineStepper-entry',
      title: 'timelineStepper-entry-title',
      detail: 'timelineStepper-entry-detail',
      retryPaymentButton: 'timelineStepper-entry-retryPaymentButton',
    },

    forms: {
      select: 'components-forms-select',
      selectBase: 'components-forms-selectBase',
      selectOptions: 'components-forms-selectOptions',
    },
    buyerCheckout: {
      companyInfo: {
        prePopulatedAddress: {
          companyName: 'buyerCheckout-companyInfo-address-companyName',
          address: 'buyerCheckout-companyInfo-address-address',
        },
        prePopulatedContact: {
          name: 'buyerCheckout-companyInfo-contact-name',
          title: 'buyerCheckout-companyInfo-contact-title',
          email: 'buyerCheckout-companyInfo-contact-email',
        },
        addressForm: {
          company: 'buyerCheckout-companyInfo-addressForm-company',
          line1: 'buyerCheckout-companyInfo-addressForm-line1',
          line2: 'buyerCheckout-companyInfo-addressForm-line2',
          city: 'buyerCheckout-companyInfo-addressForm-city',
          region: 'buyerCheckout-companyInfo-addressForm-region',
          regionSelect: 'buyerCheckout-companyInfo-addressForm-regionSelect',
          country: 'buyerCheckout-companyInfo-addressForm-country',
          zip: 'buyerCheckout-companyInfo-addressForm-zip',
        },
        contactForm: {
          firstName: 'buyerCheckout-companyInfo-contactForm-firstName',
          lastName: 'buyerCheckout-companyInfo-contactForm-lastName',
          preferredName: 'buyerCheckout-companyInfo-contactForm-preferredName',
          title: 'buyerCheckout-companyInfo-contactForm-title',
          email: 'buyerCheckout-companyInfo-contactForm-email',
        },
      },
    },
  },
  pages: {
    index: {
      signup: 'pages-index-signup',
      login: 'header-login-button',
    },

    about: {
      foundersStory: 'pages-about-foundersStory',
    },
  },
};
