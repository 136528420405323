import {Seller, Product} from 'types';
import {Theme} from 'providers/themeProvider';

const THRESHOLD = 149;

function stringToHex(val: string): number {
  return Number('0x' + val);
}

/** 
 * For a given hex color code, return the color text should be
 * */ 
export function contrast(hexCode: any = '#ffffff') {
  return shouldUseDarkColor(hexCode) ? '#222222' : '#ffffff';
}

/**  
 * Converts a color (given in "#FFFFFF" format) into grayscale, 
 * then compares it to a threshold value to determine lightness.
 * 
 * Grayscale conversion uses a well-known weighted method, 
 * as not all wavelengths contribute equally across typical
 * human visual perception.
 * @param {string} hexCode - A six character rgb hexadecimal color code
 * with preceding "#". 
 * @returns {boolean} The output of this function determines whether 
 * a dark color will maximize contrast with this input color.
 * @see {@link contrast} for example use case
 */
export function shouldUseDarkColor(hexCode: any = '#ffffff'): boolean {
  const r = stringToHex(hexCode.slice(1, 3));
  const g = stringToHex(hexCode.slice(3, 5));
  const b = stringToHex(hexCode.slice(5, 7));
  return r * 0.299 + g * 0.587 + b * 0.114 > THRESHOLD;
}

export function themeFromSeller(seller: Seller): Theme {
  return {
    primaryColor: '#' + seller.primaryColor,
    primaryContrastColor: contrast('#' + seller.primaryColor),
  };
}

export function themeFromProduct(product: Product): Theme {
  const primaryColor =
    '#' + (product.primaryColor || product.seller.primaryColor);
  return {
    primaryColor,
    primaryContrastColor: contrast(primaryColor),
  };
}
