import React, {useState, useContext} from 'react';

export interface Theme {
  primaryColor: string;
  primaryContrastColor: string;
}

interface Funcs {
  setTheme: (newTheme: Theme) => void;
  patchTheme: (newThemePatch: Partial<Theme>) => void;
}

export interface ThemeProviderProps extends Funcs {
  theme: Theme;
}

const DEFAULT_THEME: Theme = {
  primaryColor: '',
  primaryContrastColor: '',
};

export const ThemeContext = React.createContext<Partial<ThemeProviderProps>>({
  theme: DEFAULT_THEME,
});

const ThemeProvider = (props: {
  children: React.ReactNode;
  initialTheme?: Theme;
  overwriteTheme?: Theme;
}) => {
  const {initialTheme, overwriteTheme} = props;
  const [theme, setTheme] = useState<Theme>(initialTheme || DEFAULT_THEME);

  const value = {
    setTheme: (newTheme: Theme) => {
      setTheme(newTheme);
    },
    patchTheme: (themePatch: Partial<Theme>) => {
      setTheme({...theme, ...themePatch});
    },
    theme: overwriteTheme || {...theme},
  };

  return <ThemeContext.Provider value={value} {...props} />;
};

export const useThemeContext = (): ThemeProviderProps => {
  const themeContext = useContext(ThemeContext);

  if (themeContext === undefined) {
    throw new Error(
      'Attempting to read ThemeContext outside a Provider heirarchy',
    );
  }

  return themeContext as ThemeProviderProps;
};

export default ThemeProvider;
